@import '~/src/styles/_variables.scss';
@import '~/src/styles/_mixins.scss';

.body {
  margin: auto;
  text-align: center;
  img {
    margin: 10px;
  }
}
@media (max-width: 575.98px) {
  .body {
    padding: 0;
  }
}

.ballDropped {
  z-index: 99;
  color: #0a3355;
  position: absolute;
  top: 6vh;
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 36px;
  font-family: $font-family;
}

.ballDroppedXSm {
  z-index: 99;
  color: #0a3355;
  position: relative;
  text-align: center;
  top: 7vh;
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 36px;
  font-family: $font-family;
}

// xsmall
@media (max-width: 575.98px) {
  .ballDroppedXSm {
    font-size: 31px;
    position: relative;
    top: 7vh;
    text-align: center;
  }
}
// small
@media (max-width: 767.98px) {
  .ballDropped {
    position: relative;
    top: 20px;
    justify-content: center;
  }
}
// medium
@media (max-width: 991.98px) {
  .ballDropped {
    top: 30px;
    text-align: center;
  }
}

.errorMsg {
  position: absolute;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  bottom: 120px;
}
// medium
@media (max-width: 991.98px) {
  .errorMsg {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    position: relative;
    bottom: 40px;
  }
}

// small
@media (max-width: 767.98px) {
  .errorMsg {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    position: relative;
    left: 120px;
    bottom: 0px;
    width: 300px;
  }
}
// xsmall - mobile
@media (max-width: 575.98px) {
  .errorMsg {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    position: absolute;
    top: 70%;
  }
}
.errorMsgXSm {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  position: relative;
  bottom: 40px;
  margin: 0 70px 0 70px;
}
@media (max-width: 484px) {
  .errorMsgXSm {
    margin: 0 5px 0 5px;
  }
}

.goalStyle {
  margin: 0 auto;
  opacity: 1;
}

.blueButton {
  width: 210px;
  background: #0a3355;
  color: #ffffff;
  border: 1px solid #0a3355;
  box-shadow:
    0px 4px 8px rgba(38, 50, 56, 0.08),
    0px 2px 4px rgba(38, 50, 56, 0.16),
    0px -4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 3px;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.25px;
  text-transform: uppercase;
  text-align: center;
  transform: translateY(-1px);
}
.blueButton:hover {
  background: #ffffff;
  color: #0a3355;
}

@media (max-width: 575.98px) {
  .blueButton {
    width: 175px;
    margin: 5px;
    padding: 10px 5px;
  }
}
// large
@media (max-width: 991.98px) {
  .blueButton {
    margin-left: 0;
  }
}
// xlarge
@media (max-width: 1199.98px) {
  .blueButton {
    margin-left: 0;
  }
}

.blueButtonHome {
  background: #0a3355;
  color: #ffffff;
  width: 180px;
  height: 46px;
  box-shadow:
    0px 4px 8px rgba(38, 50, 56, 0.08),
    0px 2px 4px rgba(38, 50, 56, 0.16),
    0px -4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 3px;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.25px;
  text-transform: uppercase;
  text-align: center;
  transform: translateY(-1px);
  margin: 0 5px;
}
.blueButtonHome:hover {
  background: #ffffff;
  color: #0a3355;
}

.blueButtonFind {
  width: 190px;
  height: 46px;
  background: #0a3355;
  color: #ffffff;
  border: 1px solid #0a3355;
  box-shadow:
    0px 4px 8px rgba(38, 50, 56, 0.08),
    0px 2px 4px rgba(38, 50, 56, 0.16),
    0px -4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 3px;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.25px;
  text-transform: uppercase;
  text-align: center;
  transform: translateY(-1px);
  margin: 0 5px;
}
