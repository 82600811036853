@use '../variables.scss' as colors;
@use '../../../../../styles/variables' as vars;
@import './components/ReportSection/styles.module.scss';

.report-popup-content {
  p {
    text-align: start;
  }
}

button.report-link-button {
  padding: 0;
  border: none;
  color: vars.$link-color;
  &:hover {
    box-shadow: none !important;
  }
}

main.incident-report-main {
  padding-left: 3em;
  padding-right: 3em;
  padding-top: 2em;
  padding-bottom: 3em;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1em;
}

.incident-report-pdf {
  padding: 2em !important;
  display: flex !important;
  flex-direction: column !important;
}

.attending-staff-section {
  display: grid;
  grid-template-columns: 5fr 6fr;
  .staff-present-symbol {
    font-style: italic;
    font-size: 13px;
  }
}

.injury-details-section {
  display: grid;
  grid-template-columns: 5fr 6fr;
}

.altercation-details-section {
  display: grid;
  grid-template-columns: 5fr 6fr;
}

.parties-involved-section {
  .person {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1em;
  }
}

.report-witnesses-section {
  display: grid;
  grid-template-columns: 2fr 3fr;
  column-gap: 1em;
  row-gap: 0.5em;
}

.incident-report-green {
  color: colors.$incident-report-green;
}
.incident-report-yellow {
  color: colors.$incident-report-yellow;
}
.incident-report-red {
  color: colors.$incident-report-red;
}
.incident-report-black {
  color: colors.$incident-report-black;
}

@media screen and (max-width: 1200px) {
  main.incident-report-main {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 767px) {
  main.incident-report-main {
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 1em;
    padding-bottom: 1em;
  }
}

@media screen and (max-width: 576px) {
  .report-popup-content {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .attending-staff-section {
    display: flex;
    flex-direction: column;
  }
  .injury-details-section {
    display: flex;
    flex-direction: column;
    .right {
      margin-top: 0.5rem;
    }
  }
  .parties-involved-section {
    .person {
      display: flex;
      flex-direction: column;
      div {
        display: flex;
        justify-content: space-between;
        line-height: 1.6;
      }
    }
  }
  .altercation-details-section {
    display: flex;
    flex-direction: column;
  }
}
