@use '../abstracts/' as *;

.basic-container {
  position: relative;
}
.league-basics {
  border-bottom: 1px solid $neutral-03;
  border-top: 1px solid $neutral-03;

  @include media-breakpoint-down(md) {
    .orgItem {
      margin-bottom: 20px;
      text-align: left;
    }
    .orgLogo {
      max-height: 70px;
    }
  }

  .item {
    &:first-child {
      border-left: none;
    }

    > div {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;
      margin-top: 0.5rem;
      img {
        height: 22px;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .item {
      &:first-child {
        border-left: none;
      }
    }
  }

  @include media-breakpoint-down(xs) {
    .item {
      border-left: none;
      > div {
        &:last-child {
          margin-bottom: 10px;
        }
      }
    }
  }

  .rules {
    line-height: 1.6;
  }
}

.deep-link {
  position: absolute;
  right: 2px;
  bottom: -32px;
  color: #1e62b4;
  cursor: pointer;
}
