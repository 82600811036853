@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.photo-uploader {
  text-align: center;
  display: block;
}

.newPdf {
  %main-block {
    display: block;
    color: $rivall-navy;
    cursor: pointer;
    height: 3rem;
    line-height: 3rem;
    text-align: left;
    background: #ffffff;
    overflow: hidden;
    position: relative;
    border-radius: 8px;
    display: flex;
  }
  %label-block {
    height: 3rem;
    line-height: 3rem;
    min-width: fit-content;
    font-weight: 800;
    padding: 0 10px;
    text-transform: uppercase;
    display: inline-block;
  }
  %name-block {
    height: 3rem;
    line-height: 3rem;
    min-width: fit-content;
    display: inline-block;
    padding: 0 10px;
    color: $rivall-navy;
    font-weight: 600;
    overflow-wrap: break-word;
  }
  %outer-hover {
    border-color: $grey-600;
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
  }
  %inner-hover {
    background: $grey-600;
    color: #ffffff;
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
  }
  label {
    display: block;
    text-transform: uppercase;
    color: $grey-600;
  }
  .file-upload {
    text-align: center;
    font-size: 12px;
    width: 100%;
    .file-select {
      @extend %main-block;
      border: 2px solid $rivall-blue;
      .pdfInput {
        z-index: 100;
        cursor: pointer;
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        filter: alpha(opacity=0);
      }
      .file-select-button {
        @extend %label-block;
        background: $rivall-blue;
        color: $grey-200;
      }
      .file-select-title {
        @extend %name-block;
      }
      &:hover {
        @extend %outer-hover;
        .file-select-button {
          @extend %inner-hover;
        }
      }
    }
    .file-select.file-select-disabled {
      opacity: 0.65;
      &:hover {
        cursor: default;
        display: block;
        border: 2px solid #dce4ec;
        color: #34495e;
        cursor: pointer;
        height: 40px;
        line-height: 40px;
        margin-top: 5px;
        text-align: left;
        background: #ffffff;
        overflow: hidden;
        position: relative;
        .file-select-button {
          background: #dce4ec;
          color: #666666;
          padding: 0 10px;
          display: inline-block;
          height: 40px;
          line-height: 40px;
        }
        .file-select-name {
          line-height: 40px;
          display: inline-block;
          padding: 0 10px;
        }
      }
    }
  }

  .loaded {
    @extend %main-block;
    border: 2px solid $neon-yellow;
    &:hover {
      @extend %outer-hover;
    }
    .loaded-action {
      @extend %label-block;
      background: $neon-yellow;
      color: $grey-800;
      margin-right: 8px;
      &:hover {
        @extend %inner-hover;
      }
    }
    .loaded-file-name {
      @extend %name-block;
      overflow-wrap: break-word;
      line-height: 3rem;
    }
  }
}

.save-button {
  background-color: transparent;
  border-color: $lighter-neutral;
  color: $lighter-neutral;
  padding: 4px 0;
  width: 260px;
  margin: 20px auto 5px;
  max-width: 100%;
  pointer-events: none;
  cursor: default;
  text-transform: none;
  display: block;
}

.photo-uploader {
  .preview-file {
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 5px;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}
