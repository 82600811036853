.heroSlides {
  p {
    padding: 10px 0 2px;
  }
  .hero-container {
    position: relative;
    .hero {
      width: 100%;
      background-size: cover;
      border-radius: 3px;
      min-height: 350px;
      position: relative;
      .jumbotron {
        background: #03111bc7;
        border-radius: 3px;
        padding: 40px;
        margin: 20px 20%;
        width: 60%;
        min-height: 250px;
        position: absolute;
        top: calc(50% - 25px);
        transform: translateY(-50%);
        text-align: center;
      }
      .title {
        width: fit-content;
        width: -moz-fit-content; /* Firefox */
        width: -webkit-fit-content; /* Chrome */
        font-weight: 900;
        line-height: 30px;
        letter-spacing: 3.5px;
        color: #ffffff;
        border-bottom: 3px solid #e9ff70;
        text-transform: uppercase;
        margin: 0 auto;
        text-align: center;
      }
      .subtitle {
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        margin: 15px 0;
        text-align: center;
        letter-spacing: 0.25px;
        color: #ffffff;
        white-space: pre-line;
      }
      .ctaButton {
        background: transparent;
        border: 2.6px solid #e9ff70;
        padding: 10px 30px;
        border-radius: 3px;
        font-weight: bold;
        font-size: 16px;
        letter-spacing: 0.25px;
        text-transform: uppercase;
        &:hover {
          -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
        }
        a {
          color: #e9ff70;
        }
      }
    }
    .hero-img {
      width: 100%;
    }
    .delete-hero-image {
      position: absolute;
      right: 7px;
      top: 8px;
      background: red;
      border-radius: 50%;
      padding: 5px;
    }
  }
  .card {
    background: #ffffff;
    border-radius: 16px;
    text-align: left;
    padding: 20px 25px;
    box-shadow:
      0px 4px 8px rgba(38, 50, 56, 0.08),
      0px 2px 4px rgba(38, 50, 56, 0.16),
      0px -4px 4px rgba(0, 0, 0, 0.03);
    width: 100%;
    margin-bottom: 15px;
  }
  .delete-slide {
    border: 1px solid darkred;
    background: transparent;
    color: darkred;
    width: 100%;
    margin-top: 10px;
  }
  .slide-duration {
    padding: 10px;
    font-size: 16px;
  }
}
