@use '../abstracts/' as *;

.table-defaults {
  table-layout: fixed;
  width: 100%;
  border-radius: 4px;

  th {
    padding: 0.5rem;
    text-align: center;
  }
  td {
    padding: 0.5rem;
  }
}
