@import '../../../styles/_mixins.scss';
@import '../../../styles/_variables.scss';

.archive-you-sure {
  h3 {
    font-size: 1.2em;
  }
  p {
    width: 90%;
    margin: 20px 5% 0;
    display: block;
  }
  button {
    transition: all 0.2s ease;
    padding: 8px 40px;
    margin: 25px 5px 0;
    font-weight: 400;
    border-radius: 2px;
    box-shadow: 0 0 0 0 #262626;
    cursor: pointer;

    &:hover {
      box-shadow: 0 0 0 2px #262626;
    }

    &.yes {
    }

    &.no {
      color: #fff;
      background: #9ce2db;
      border-color: #9ce2db;
      box-shadow: 0 0 0 0 #9ce2db;

      &:hover {
        box-shadow: 0 0 0 2px #9ce2db;
      }
    }
  }
}
