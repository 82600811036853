@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.org-policies-resources {
  .orgImg {
    img {
      max-width: 100%;
      max-height: 120px;
    }
  }

  .org-basics {
    font-size: 16px;
    margin: 5px 0;
    a {
      color: $med-neutral;
    }
  }

  .org-policies {
    text-align: center;
    margin: 0 auto;
  }
  .hover-message {
    font-size: 14px;
    color: white;
  }
  .policy-items {
    text-align: center;
    margin-top: 20px;
  }
  .policy-item {
    display: inline-block;
    text-align: center;
    padding: 10px 20px 0;
    cursor: pointer;
    img {
      height: 28px;
      @include transition(0.3s);
    }
    h4 {
      font-size: 12px;
      font-weight: 400;
      color: $text-color;
    }

    &:hover {
      img {
        opacity: 0.7;
      }
    }
  }
  .policy-html {
    img {
      height: 28px;
    }
    h4 {
      font-size: 16px;
      margin-bottom: 10px;
    }
    .html {
      font-size: 13px;
      text-align: left;
    }
  }

  margin-top: 15px;

  .container {
    text-align: center;
  }

  .org-policies {
    border-radius: 2px;
    background-color: white;
  }
  .hover-message {
    color: $text-color;
    font-weight: 500;
  }
  .policy-item h4 {
    color: $text-color;
  }
}

@media screen and (max-width: 600px) {
  .org-policies-resources {
    .container {
      flex-direction: column;

      .org-icon {
        flex: 1;
        border-right: none;
        padding: 0;
        width: 100%;
        text-align: center;

        img {
          width: auto;
          height: 140px;
        }
      }

      .org-text {
        flex: 1;
        border-left: none;
        margin-left: 0;
        padding: 10px 15px 0;
      }
    }
    .hover-message {
      display: none;
    }

    .policy-item {
      padding: 8px;

      h4 {
        display: block;
        font-size: 12px;
        font-weight: 400;
        color: $text-color;
      }

      &:hover {
        img {
          opacity: 0.7;
        }
      }
    }
  }
}
