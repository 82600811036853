@use 'sass:math';

@import '~/src/styles/_variables.scss';
@import '~/src/styles/_mixins.scss';
@import '../../themes.module.scss';

$week-padding: 10px;
$game-time-length: 80px;

@mixin item {
  position: relative;
  display: inline-block;
  vertical-align: top;
  text-align: left;
}
.save {
  @include button($dark-neutral);
  width: 260px;
  margin: 20px auto 0;
  &.disabled {
    @include ghost-button($light-neutral);
  }
}
.week-item {
  @include item;
  width: 100%;
  padding: ($week-padding - 2) 0;
  border: 1px solid $light-neutral;
  border-radius: 2px;
  margin: 10px;
  position: relative;
  .week-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    h2 {
      font-size: 18px;
      padding: 0 $week-padding 4px;
      font-weight: 400;
      flex: 2;
    }
    .week-notes {
      flex: 3;
    }
    .target-actions {
      flex: 1;
      img {
        margin-top: 10px;
      }
    }
  }
  .week-footer {
    .add-day-btn {
      @include button($main);
      padding: 3px 10px;
      border-radius: 20px;
      margin: 10px 8px 6px;
    }
  }
  .playoff-week {
    background-color: $white-neutral;
    color: $neutral;
    width: calc(100% - 10px);
    text-align: center;
    padding: 40px 0;
    margin: 10px 5px 0;
    border-radius: 8px;
    span {
      font-size: 1.2em;
      letter-spacing: 1px;
    }
  }
}

.week-item,
.day-item {
  .target-actions {
    position: absolute;
    top: 7px;
    right: 10px;
    img {
      vertical-align: middle;
      height: 16px;
      cursor: pointer;
      margin-left: 10px;
      &:hover {
        opacity: 0.6;
      }
    }
  }
}
.edit-week {
  h2 {
    font-size: 18px;
    padding: 0 $week-padding;
    font-weight: 400;
  }
}
.select-new-date {
  h2 {
    font-size: 18px;
    padding: 0 $week-padding;
    font-weight: 400;
  }
  p {
    margin: 10px auto;
    font-size: 14px;
    color: $med-neutral;
  }
  a {
    display: block;
    font-size: 14px;
    &.selected {
      font-weight: 600;
    }
  }
  .game-time-picker {
    margin: 0 auto;
  }
  button {
    margin-top: 20px;
  }
}
.day-item {
  @include item;
  width: 100%;
  h3 {
    width: 100%;
    font-size: 14px;
    font-weight: 300;
    background-color: $white-neutral;
    padding: 7px $week-padding;
    margin: 3px 0;
  }
}
.select-new-date {
  text-align: center;
  p {
    margin: 10px auto;
    font-size: 14px;
    color: $med-neutral;
  }
  a {
    display: block;
    font-size: 14px;
    &.selected {
      font-weight: 600;
    }
  }
  hr {
    margin: 20px 0 15px;
  }
  button {
    margin-top: 20px;
  }
  .game-date-picker {
    margin: 0 auto;
  }
}
.add-game {
  padding: 0 $week-padding;
  cursor: pointer;
  margin: 5px 0 10px;
  font-weight: 400;
  display: block;
  color: $orange;
  font-size: 12px;
  &:hover {
    background-color: $white-neutral;
  }
  .add-plus {
    font-weight: 600;
  }
}
.game-item {
  width: 100%;
  padding: 2px $week-padding;
  position: relative;
  border-bottom: 1px solid $lighter-neutral;
  padding-bottom: 6px;
  p {
    vertical-align: middle;
    display: inline-block;
    position: relative;
    width: 20%;
    font-size: 12px;
    &.sub-location {
      padding: 0 0 0 8px;
      &:before {
        content: '@ ';
        position: absolute;
        color: $lightish-neutral;
        font-size: 12px;
        left: -8px;
        top: 0;
      }
    }
  }
  .game-time {
    font-size: 12px;
    font-weight: 600;
    display: inline-block;
    width: $game-time-length;
    vertical-align: middle;
    pointer-events: none;
  }
  .game-tools {
    position: absolute;
    top: 0;
    right: 10px;
    img {
      display: inline-block;
      cursor: pointer;
      width: math.div(34px, 2);
      vertical-align: middle;
      margin: 0 3px;
      &:hover {
        opacity: 0.8;
      }
    }
    :first-child {
      position: relative;
      top: 0.75px;
      margin-right: 5px;
    }
    :last-child {
      margin: 3px 0 0 0;
      height: 20px;
    }
  }
}
// Team placeholder
.team-placeholder {
  @include item;
  &:last-child {
    padding: 0 0 0 8px;
    &:before {
      content: 'vs.';
      position: absolute;
      color: $lightish-neutral;
      font-size: 10px;
      left: -8px;
      top: 2px;
    }
  }
  img {
    height: 12px;
    width: auto;
    position: relative;
    top: 1px;
    margin-left: 4px;
  }
}

.add-game-popup {
  .field-wrap {
    text-align: center;
    max-width: 50%;
    min-width: 150px;
    padding: 10px;
    display: inline-block;
    vertical-align: top;
    > * {
      margin: 0 auto;
      max-width: 100%;
    }
  }
  .slider-wrap {
    max-width: 260px;
    margin: 20px auto;
    p {
      font-size: 14px;
    }
  }
}

.vp-game-container {
  width: 100%;
  background-color: #fcfcfc;
  border-bottom: 1px solid #e8ebeb;
  .vp-game-details {
    border-left: 1px solid #e8ebeb;
    border-right: 1px solid #e8ebeb;
    margin-left: $game-time-length;
    margin-right: calc(60% - $game-time-length);
    font-size: 12px;
    h3 {
      padding: 0px 10px;
      margin: 0;
    }
    .vp-team {
      &:first-of-type {
        border-right: 1px solid #e8ebeb;
      }
      .vp-item {
        padding: 6px;
        .response {
          height: 11px;
          width: auto;
          position: relative;
          top: 1px;
          margin: 0 3px;
        }
      }
      .vp-user-item {
        .response,
        .captain-icon,
        .profile-img {
          top: 0px;
          position: absolute;
          right: 15px;
        }
        .captain-icon,
        .profile-img {
          left: 15px;
          top: -2px;
          height: 16px;
        }
      }
    }
  }
  hr {
    background-color: #e8ebeb;
  }
}

.volo-pass-popup {
  h2 {
    position: relative;
    background: white;
    top: 36px;
    text-align: left;
    margin-left: 42px;
    width: fit-content;
    width: -moz-fit-content; /* Firefox */
    width: -webkit-fit-content; /* Chrome */
    color: #1e62b4;
    font-weight: normal;
  }
  .requirements-body {
    margin: 25px;
    border: 1px solid $primary;
    border-radius: 10px;
    padding: 10px 0;
  }
  .requirement-checkbox {
    span {
      color: $primary;
    }
    text-align: left;
  }
  .requirement-textbox {
    width: 44px;
    height: 16px;
    text-align: left;
    input {
      text-align: center;
      padding: 0;
      border: 0.5px solid #a6a6a6;
      border-radius: 2px;
      box-shadow: none;
    }
  }
  .requirement-textbox,
  .requirement-checkbox {
    display: block;
    margin: 14px 0;
  }
}
