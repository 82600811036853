@import '~/src/styles/_variables.scss';

.root {
  // width: 260px;
  // margin: 20px auto;
  text-align: left;
  @include placeholder {
    color: $input-placeholder-color;
  }

  &.loading {
    input {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}

.places-autocomplete-wrap {
  text-align: left;
  border: 1px solid $border-color;
  @include ev-2;
  margin-top: -1px;
  font-size: inherit;
}

.autocomplete-item {
  padding: 0;
  &:hover {
    background-color: $bg-light;
  }
}

input.places-input {
  width: 100%;
  border: 1px solid $grey-input-color;
  background-color: $grey-input-color;
  padding: 10px;
  color: $text-color;
  font-size: inherit;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  @include transition(0.3s);
}

p.places-label {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  margin-left: 1px; //border offset :)
  margin-bottom: 0px;
  color: $dark-neutral;
}

.loader {
  position: absolute;
  width: 20px;
  right: 8px;
  bottom: 5px;
  img {
    margin: 0;
    width: 100%;
    height: auto;
  }
}
