@import '../../styles/_variables.scss';

.message {
  display: inline-block;
  padding: 5px 10px;
  margin: 10px auto;
  font-size: 14px;
  color: $dark-neutral;
  font-style: normal;
  font-weight: 400;

  &.warning-message {
    // background-color: $warning;
  }

  &.normal-message {
    // background-color: $light-neutral;
  }
  &.success-message {
    color: $success;
  }
  &.error-message {
    color: $error;
  }
}

.message-list-wrap {
  width: 720px;
  display: block;
  margin: 10px auto;
  position: relative;

  .prev-next {
    position: absolute;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%);

    &.left {
      left: -20px;
    }

    &.right {
      right: -20px;
    }

    img {
      width: 16px;
    }
  }

  .message-list {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 80px;

    .inner-list {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: row;
      width: 100%;
      -webkit-transition: left 0.5s; /* Safari */
      transition: left 0.5s;

      a.error-item {
        padding: 10px 15px;
        border-radius: 10px;
        background-color: $tangerine;
        color: #fff;
        margin: 0 5px;
        display: inline-block;
        text-align: left;
        flex: none;
        width: 225px;
        height: 80px;
        cursor: pointer;

        &:hover {
          color: #fff;
        }

        h5 {
          font-size: 0.75em;
          max-width: 95%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
          margin: 0;
        }

        p {
          font-size: 0.85em;
        }
      }
    }
  }
}
