.format-table {
  b {
    padding: 15px 0 5px;
    display: block;
  }
  table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    tbody {
      width: 100%;
      display: initial;
    }
    th {
      border: 1px solid black;
      padding: 5px 10px;
      font-weight: normal;
    }
  }
}
