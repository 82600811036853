@import '~/src/styles/_variables';

@mixin tooltipcolor($color: $grey-200) {
  background-color: $color !important;
  color: white !important;
  &:global(.place-top):after {
    border-top-color: $color !important;
  }
  &:global(.place-right):after {
    border-right-color: $color !important;
  }
  &:global(.place-left):after {
    border-left-color: $color !important;
  }
  &:global(.place-bottom):after {
    border-bottom-color: $color !important;
  }
}

.tooltip {
  @include tooltipcolor;
  line-height: 1.3;
  font-size: 12px;
  font-family: $font-family;
  max-width: 320px;
  text-align: center;
  font-weight: 400;

  &:global(.type-dark),
  .dark {
    @include tooltipcolor(rgba(0, 0, 0, 0.8));
  }
  &:global(.type-info),
  .info {
    @include tooltipcolor($info);
  }
  &:global(.type-error),
  .danger,
  .error {
    @include tooltipcolor($danger);
  }
  &:global(.type-success),
  .success {
    @include tooltipcolor($success);
  }
  &:global(.type-warning),
  .warning {
    @include tooltipcolor($warning);
  }
  &:global(.type-light),
  .light {
    @include tooltipcolor($grey-200);
    color: $text-color !important;
  }
}
