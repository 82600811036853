// Design System V1

// main colors
$volo-primary: #0a3355;
$volo-primary-light: #e9f4fc;
$volo-secondary: #4ce0d2;
$volo-secondary-light: #f6fdfd;
$volo-accent: #eaff70;
$volo-accent-light: #f9ffd6;

$volo-medium-blue: #0d90db;

// semantic colors
$danger-base: #e63946;
$danger-light: #fbdfe1;
$danger-dark: #c31925;

$success-base: #29bf12;
$success-light: #dafbd5;
$success-dark: #22a00f;

$warning-base: #ffdc33;
$warning-light: #fff7dd;
$warning-dark: #ffc300;

$link-base: #428ffc;
$link-light: #e9f2ff;
$link-dark: #0353c4;

// neutrals
$neutral-01: #f5f5f5;
$neutral-02: #e9e9e9;
$neutral-03: #d9d9d9;
$neutral-04: #c4c4c4;
$neutral-05: #9d9d9d;
$neutral-06: #7b7b7b;
$neutral-07: #555555;
$neutral-08: #434343;
$neutral-09: #262626;
$neutral-10: #000000;

/* -------------------- */

// paddingDefaults
$padding-xs: 10px;
$padding-sm: 15px;
$padding-md: 20px;
$padding-lg: 30px;
$padding-xl: 40px;

// margin defaults
$margin-xs: 5px;
$margin-sm: 7.5px;
$margin-md: 10px;
$margin-lg: 15px;
$margin-xl: 20px;
