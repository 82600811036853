@import '~/src/styles/_variables.scss';

$focus-color: $primary;
$error-color: $danger;

@mixin include-placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}
// $label-height: 15px;

input.fancyField,
input[type='text'].fancyField,
select.fancyField,
textarea.fancyField {
  border: 0;
  -webkit-appearance: none;
  padding: 8px 0;
  outline: 0;
  border-radius: 0;
  background-color: transparent;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  transition:
    color 0.3s,
    box-shadow 0.3s,
    border 0.3s;
  line-height: 1;
  height: auto;
  color: $text-color;
  box-shadow: inset 0px -1px 0px $input-border-color;
  &.heavy {
    box-shadow: inset 0px -2px 0px $input-border-color;
  }
  &.editableText {
    box-shadow: inset 0px -1px 0px transparent;
    &:hover:not(:focus) {
      box-shadow: inset 0px -1px 0px $input-border-color;
    }
  }

  &:focus {
    box-shadow: inset 0px -2px 0px $focus-color;
  }

  &.error:not(.disabled) {
    box-shadow: inset 0px -1px 0px $error-color;
    &.heavy {
      box-shadow: inset 0px -2px 0px $error-color;
    }
  }

  &:disabled,
  &.disabled {
    color: $input-disabled-color;
    background-color: $input-disabled-bg;
    @include include-placeholder {
      color: $input-disabled-color;
    }
    pointer-events: none;
  }

  &.border,
  &.grey,
  &.color {
    border: 1px solid $input-border-color;
    background-color: white;
    box-shadow: none;
    padding: $input-padding-y $input-padding-x;
    background-position: right 5px center;
    border-radius: 100px;
    &:focus {
      box-shadow: none;
      border-color: $focus-color;
    }

    &.error:not(.disabled) {
      box-shadow: none;
      border-color: $error-color;
    }

    &:disabled,
    &.disabled {
      color: $input-disabled-color;
      background-color: $input-disabled-bg;
      @include include-placeholder {
        color: $input-disabled-color;
      }
    }
  }

  &.short {
    height: 26px !important;
  }

  @include include-placeholder {
    color: $input-placeholder-color;
    transition: color 0.3s;
  }
  &:focus:not(.persistPlaceholder) {
    @include include-placeholder {
      color: transparent !important;
    }
  }

  &.readonly {
    pointer-events: none !important;
    background-image: none !important;
  }

  &.grey {
    border-color: $grey-input-color;
    background-color: $grey-input-color;
    &:focus {
      box-shadow: none;
      border-color: $grey-input-color;
    }
  }

  &.blockStyle {
    ::placeholder {
      color: #4b506d;
    }
    border-color: #f0f1f7;
    background-color: #fcfdfe;
    background: #fcfdfe;
    border: 1px solid #f0f1f7;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: none;
    padding-left: 16px;
  }

  &.searchIcon {
    background-image: url('../../images/v2icons/search@3x.png');
    background-size: 32px;
    background-position: left 5px center;
    padding-left: 36px;
  }

  &.loading,
  &.verified {
    background-image: url('../../images/loaders/bball-small.gif');
    background-size: 16px;
    background-position: right 5px center;
    transition: background-image 0.3s;
    padding-right: 22px;
  }
  &.verified {
    background-image: url('../../images/v2icons/check-dark-circle@3x.png');
  }

  &.color {
    -webkit-appearance: none;
    border: none;
    height: 39px;
    padding: 0;
  }
  &.color::-webkit-color-swatch-wrapper {
    padding: 0;
  }
  &.color::-webkit-color-swatch {
    border-radius: 100px;
    border: none;
  }
}

textarea.fancyField {
  resize: none;
  line-height: 1.3;
  &.border {
    border-radius: 10px;
  }
  &.grey {
    border-radius: 17px;
  }
}

select.fancyField:not(.populated) {
  color: $input-placeholder-color;
}

// hack to prevent Chrome's yellow autofill styles.
@-webkit-keyframes autofill {
  to {
    background: transparent;
  }
}
.fancyField:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

.formGroup {
  text-align: left;
  line-height: 1;
  position: relative;

  p {
    margin: 0;
  }
  .label,
  .helpText {
    transition: 0.3s;
    color: $text-muted;
    font-size: 0.7rem;
  }

  .helpText {
    opacity: 0;
    margin-left: 1px;
    &.empty {
      min-height: 0px;
    }
    &.focused,
    &.disabled,
    &.error {
      opacity: 1;
      margin-top: 3px;
      &:not(.empty) {
        min-height: 14px;
      }
    }
    &.error:not(.disabled) {
      color: $error-color;
    }
  }

  .label {
    pointer-events: none;
    line-height: 1;
    position: relative;
    bottom: -20px;
    opacity: 0;
    display: inline-block;
    &.focused,
    &.populated {
      bottom: -1px;
      opacity: 1;
      &.disabled {
        bottom: 1px;
      }
    }
    &.focused {
      color: $primary;
    }
    &.error:not(.disabled) {
      color: $error-color;
    }
    &.border,
    &.grey {
      background: linear-gradient(to top, white 50%, transparent 50%);
      padding: 2px;
      position: absolute;
      top: 0px;
      left: 5px;
      bottom: auto;
      &.focused,
      &.populated {
        top: -9px;
      }
      &.disabled {
        opacity: 0;
        // color: $input-disabled-color;
        // background-color: transparent;
        // background: transparent;
      }
    }
    &.grey {
      background: transparent;
      color: $grey-500;
      &.focused {
        // top: 0px;
        color: $primary;
      }
    }
  }
}
