@use '../abstracts/' as *;
@use '../abstracts/registerVariables' as rv;

.register-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: auto;
  $header-height: 60px;
  $right-rail-width: 370px;

  @include hide-scrollbars;

  .register-header {
    display: none;
    background-color: $volo-primary;
    font-size: 0.9rem;
    font-weight: bold;
    text-transform: uppercase;

    a {
      color: white;
    }
  }

  .register-main {
    display: flex;
    justify-content: center;
    flex: 1;
    position: relative;
    .register-screens {
      height: fit-content;
      .register-data-container {
        height: fit-content;
        padding-top: 13.5rem;
        padding-bottom: 5rem;
      }
    }

    .register-vp-banner {
      left: 0;
      bottom: 0;
      width: 100%;
      position: absolute;
    }

    .register-right-rail {
      box-shadow: none;
      height: auto;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  @media (min-width: rv.$breakpoint-md) {
    .register-header {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      height: $header-height;
    }
    .register-main {
      height: 100%;
      display: flex;
      .register-screens {
        flex: 1;
        width: calc(100% - $right-rail-width);
        .register-data-container {
          overflow-y: scroll;
          padding-top: 0;
        }
      }

      .register-right-rail {
        box-shadow: -3px 0px 10px rgba(0, 0, 0, 0.15);
        width: $right-rail-width;
        margin: 0 auto;
        z-index: 100;
        height: auto;
        position: unset;
        top: auto;
      }

      .register-vp-banner {
        z-index: 200;
      }
    }
  }
}
