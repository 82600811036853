@use './volo/index';
@import '_variables';
@import '_normalize';
@import 'defaults';

// animations have to be defined like this to be accessible globally
@keyframes :global(textflash) {
  0% {
    color: $grey-600;
  }
  50% {
    color: $grey-500;
  }
  100% {
    color: $grey-600;
  }
}

@keyframes :global(opacityflash) {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}

:global {
  @import 'volo/';

  @import 'bootstrap/variables', 'bootstrap/utilities/align', 'bootstrap/utilities/background',
    'bootstrap/utilities/display', 'bootstrap/utilities/flex', 'bootstrap/utilities/sizing',
    'bootstrap/utilities/spacing', 'bootstrap/utilities/text', 'bootstrap/utilities/position',
    'bootstrap/bootstrap-grid', 'bootstrap/mixins/box-shadow', 'bootstrap/navbar';

  @import 'theme';
}
