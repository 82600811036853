.edit-game-popup {
  label {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    margin-left: 3px;
    margin-bottom: 3px;
    color: #262626;
    text-align: left;
    display: block;
  }
  .creator {
    font-size: 12px;
    text-align: left;
  }

  .icon-img {
    cursor: pointer;
    height: 20px;
    margin-top: 14px;
  }

  .add-location-link {
    font-size: 0.75em;
    font-weight: 700;
    margin-top: 5px;
    text-align: left;
  }
}
