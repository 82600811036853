@import 'bootstrap/mixins';
@import 'bootstrap/functions';
@import 'colors.scss';
@import 'colors.sports.scss';
@import 'why-you-play.scss';
@import 'mixins.scss';
@import 'elevation.scss';

// THEME
$action: $tangerine;
$primary: $rivall-blue;
$error: $brick-red;
$danger: $brick-red;
$success: $dusty-teal;
$warning: $sun-yellow;

$btn-disabled-bg: $white-neutral;
$btn-disabled-color: $lightish-neutral;

$text-color: $grey-800;
$text-muted: $grey-400;

$border-color: $grey-200;
$bg-light: $grey-100;
$light: $grey-100;

$grey-input-color: $grey-100;
$input-border-color: $grey-400;
$input-disabled-color: $btn-disabled-color;
$input-disabled-bg: $btn-disabled-bg;
$input-placeholder-color: $text-muted;
$input-padding-y: 0.625rem;
$input-padding-x: 0.625rem;

$font-family:
  Arial,
  Helvetica Neue,
  Helvetica,
  sans-serif;

$max-container-width-player: 820px;

// Layout sizes
$navbar-height: 40px;
$navbar-padding: 10px;
$static-bar-width: 60px;
$menu-width: 180px;

$header-height: $navbar-height;

// Responsive thresholds. Deprecate in favor of bootstrap classes
$max-width: 1600px;
$max-content-width: 1400px;
$max-container-width: 1200px;
$tablet-width: 720px;
$mobile-width: 480px;

$manage-sidebar-width: 90px;

// have to import vars down here, otherwise bootstrap vars arent overwritten
@import 'bootstrap/variables';
