@use '../../abstracts/' as *;

@mixin card-sections {
  .photo {
    grid-area: photo;
  }
  .name {
    grid-area: name;
  }
  .features {
    grid-area: features;
  }
  .banners {
    grid-area: banners;
  }
  .pricing {
    grid-area: pricing;
  }
  .address {
    grid-area: address;
  }
  .timing {
    grid-area: timing;
  }
  .format {
    grid-area: format;
  }
  .skill-levels {
    grid-area: skill-levels;
  }
}

@mixin card-grid {
  display: grid;
  grid-template-columns: 1fr 1.25fr 0.75fr;
  grid-template-rows: 1fr 0.75fr 0.75fr 0.75fr 0.75fr 1.25fr;
  gap: 2px $padding-xs;
  grid-template-areas:
    'photo name name'
    'photo address banners'
    'photo timing banners'
    'photo format format'
    'photo skill-levels pricing'
    'photo features pricing';
  height: 175px;
}

@mixin card-grid-short {
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1.25fr 0.75fr 0.75fr 0.75fr 0.75fr 1.75fr;
  gap: 2px $padding-xs;
  grid-template-areas:
    'photo name'
    'photo address'
    'photo timing'
    'photo format'
    'banners skill-levels'
    'banners pricing';
  height: 224px;
}

@mixin banner-basics {
  align-items: center;
  border-radius: 4px;
  color: $rivall-navy;
  font-size: 10px;
  font-weight: 800;
  letter-spacing: -0.33px;
  margin-bottom: 4px;
  max-height: 32px;
  max-width: 156px;
  min-width: 82px;
  overflow: hidden;
  padding: 4px;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

@mixin short-banner-addon {
  max-height: 150px;
  text-overflow: wrap;
  overflow: hidden;
  white-space: normal;
  letter-spacing: -0.4px;
  font-size: 9px;
}

@mixin section-styles {
  .photo {
    display: flex;
    position: relative;
    .program-image {
      flex: 1;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &.volo-pass-exclusive {
      border-radius: 4px;
      border: 6px solid #3f3ea2;
      background-color: #3f3ea2;
      border-bottom-width: 0;
      flex-direction: column;
      justify-content: space-between;
      .photo-footer {
        display: flex;
        align-items: center;
        width: 100%;
        height: 36px;
        background-color: #3f3ea2;
        .photo-footer-text {
          color: white;
          font-weight: bold;
        }
      }
    }
  }

  .name {
    align-items: flex-end;
    color: black;
    display: flex;
    font-size: 16px;
    font-weight: 800;
    letter-spacing: -0.5px;
    text-transform: capitalize;
    padding-top: 0.5rem;
    padding-bottom: 0.1rem;
  }

  .features {
    .venue-features {
      display: flex;
      .feature {
        margin-right: $margin-sm;
        height: 22px;
      }

      .feature-overflow {
        background-color: $grey-100;
        font-size: 14px;
        padding: 4px 2px;
        border-radius: 24px;
        cursor: default;
        max-height: 22px;
        color: black;

        &.truncate {
          overflow: hidden;
          text-overflow: ellipsis;

          &:hover {
            overflow: scroll;
            text-overflow: unset;
          }
        }

        span {
          white-space: nowrap;
        }
      }
    }
  }

  .banners {
    align-items: flex-end;
    flex-direction: column;

    .banner-message {
      @include banner-basics;
      background-color: $neon-yellow;

      &:hover {
        max-height: 150%;
        overflow: visible;
        text-overflow: wrap;
        white-space: normal;
      }
    }

    .banner-phase {
      @include banner-basics;
      background-color: $light-teal;
    }
  }

  .pricing {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 1.5rem;

    .infoLabel {
      text-align: right;
      font-size: 10px;
      font-style: italic;
      height: 11px;
    }

    .price {
      @include transition(0.5s);
      display: flex;
      justify-content: flex-end;
      max-height: 16px;

      .currentPrice {
        display: flex;
        order: 2;
        color: $grey-800;
        font-size: 0.9rem;
        font-weight: 600;
        letter-spacing: -0.5px;
        justify-content: flex-end;
        align-items: center;
        white-space: nowrap;

        > span {
          font-size: 11px;
        }
      }

      .basePrice {
        display: flex;
        order: 1;
        color: $grey-600;
        font-size: 14px;
        font-weight: 100;
        letter-spacing: normal;
        align-items: center;
        margin-right: 8px;
        text-decoration: line-through;
      }

      .teamPrice {
        font-size: 12px;
        font-weight: 600;
        display: flex;
        align-items: center;
        min-height: 12px;

        > span {
          font-size: 11px;
        }
      }
    }

    .VPlabel {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      p {
        background-color: $volo-primary-light;
        text-transform: uppercase;
        color: $volo-primary;
        font-size: 8px;
        font-weight: bold;
        padding: 0.5rem;
        letter-spacing: 0.02rem;
      }
    }

    .VPlogo {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background-color: white;
      box-shadow: 0 0 4px rgba(30, 98, 180, 0.15);
      position: relative;
      margin-right: -5px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 75%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  }

  .member-pricing {
    display: flex;
    align-content: center;
    justify-content: space-between;
    background-color: #fff;
    border: 1px solid $rivall-blue-300;
    border-radius: 4px;
    color: $rivall-blue-300;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 10px;
    padding: 0.3rem 0.6rem;
    margin: 0;
    width: 100%;
    max-width: 240px;
    position: absolute;
    z-index: 999;
    left: 65%;
    bottom: -5%;

    span {
      margin: auto 0.2rem;
    }

    .original-price {
      text-decoration: line-through;
      font-weight: 400;
      color: $grey-600;
    }

    .member-price {
      font-size: 16px;
    }
  }
}

@mixin short-section-styles-addon {
  padding: $padding-xs;

  .name {
    align-items: flex-start;
    white-space: normal;
    font-size: 0.9rem;
  }

  .photo {
    text-align: center;
    img {
      width: 100%;
    }
  }
  .features {
    display: none;
  }

  .banners {
    align-items: center;

    .banner-phase,
    .banner-message {
      @include short-banner-addon;
      width: 100%;
      max-height: 50px;
    }
  }

  .pricing {
    .infoLabel {
      margin-bottom: 0.25rem;
    }
  }

  .member-pricing {
    left: 30%;
  }
}

.loading {
  display: flex;
  margin: 1rem auto;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 16px;
  justify-content: center;
  padding-right: $padding-xs;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
  @media (min-width: 695px) {
    width: 675px;
    height: 175px;
  }

  @media (max-width: 694px) {
    width: max(325px, 100% - 10px);
    height: 215px;
  }
}

.discover-card {
  cursor: pointer;
  margin: 1rem auto;
  border-radius: 4px;
  position: relative;
  margin-bottom: 16px;
  padding-right: $padding-xs;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);

  &.isAnchored {
    border: 4px solid $light-teal;
    &::before {
      content: 'Featured';
      position: absolute;
      top: -14px;
      left: 50%;
      background: white;
      color: $rivall-blue;
      font-weight: bold;
      padding: 0 10px;
      font-size: 16px;
    }
  }

  &.selected,
  &:hover {
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
    background-color: lighten($rivall-blue, 56);
  }

  .details {
    display: flex;
    align-items: center;
    letter-spacing: -0.25px;

    .overflow-icon {
      overflow: auto;
      text-overflow: ellipsis;
    }

    .icon {
      flex-shrink: 0;
      height: 18px;
      display: flex;
      justify-content: center;

      img {
        height: 100%;
      }
    }

    p {
      flex-grow: 1;
      padding: 0 8px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: initial;
    }
  }

  @include transition(0.33s);
  @include card-sections;
  @include section-styles;

  @media (min-width: 695px) {
    @include card-grid;
    width: 675px;
  }

  @media (max-width: 694px) {
    width: max(325px, 100% - 10px);
    @include card-grid-short;
    @include short-section-styles-addon;
  }
}
