@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.group {
  .instructions {
    font-size: 14px;
  }
}

.login {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;

  input {
    font-size: 14px;
  }

  button {
    //   @include button($main);
    width: 260px;
    //   padding: 6px 0;
    font-size: 18px;
    margin: 0 auto 5px;
    //   &.disabled {
    //     @include ghost-button($light-neutral);
    //   }

    //   // background-image: url("../../images/loaders/bball-small.gif");
  }

  .fb-login {
    @include button($facebook);
    width: 260px;
    padding: 6px 0;
    font-size: 18px;
    margin: 20px auto;
  }

  .forgot,
  .reset {
    @include link($dark-neutral);
    @include emph;
    font-weight: 300;
  }

  &.forgot,
  &.reset {
    margin: 40px auto;
    .logo {
      width: 100px;
    }
    .forgot-title {
      margin: 10px auto 30px;
    }
  }
}
