@import '~/src/styles/_variables.scss';

.equalRows {
  font-size: 13px;
  display: table;
  border-collapse: collapse;
  width: 100%;
  .listHeader {
    padding-top: 10px;
    padding-bottom: 4px;
    border-bottom: 1px solid #9fa2b4;
    margin: 0 !important;
    font-size: 16px;
    color: #9fa2b4;
    font-weight: bold;
  }
  .listRow {
    border-bottom: 1px solid #e7e7f0;
    padding: 10px 0;
    margin: 0 !important;
    color: #4c4c4c;
  }
  & > .listHeader,
  & > .listRow {
    text-align: center;
    display: table-row;
    & > * {
      display: table-cell;
      padding: 10px 1px;
      vertical-align: middle;
      max-width: 250px;
      line-height: 18px;
    }
  }
  .clickable:hover {
    background: #f5f5f5;
    cursor: pointer;
  }
}
