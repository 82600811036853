@import '~/src/styles/_variables.scss';

.headerList {
  text-transform: uppercase;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  margin: 0 auto;
  span {
    text-align: center;
    width: 100%;
    border-bottom: 2px solid #e5e5e5;
    padding-bottom: 5px;
    color: #666666;
    &.activeTab {
      color: $primary;
      border-bottom: 2px solid $primary;
    }
    &:hover {
      cursor: pointer;
    }
  }
}
