@use '../abstracts/' as *;

@mixin card-basics {
  background-color: white;
  border-radius: 4px;
  box-shadow: $default-shadow;
  padding: $padding-sm;
  margin-bottom: $margin-lg;

  @include media-breakpoint-up(md) {
    margin-bottom: 0;
  }
}

.basic-card {
  @include card-basics;
  height: 100%;
  h2 {
    color: $volo-primary;
    font-size: 1.33rem;
    margin: $margin-lg 0;
    text-transform: uppercase;
  }

  h3 {
    color: $volo-primary;
    font-size: 1.33rem;
    letter-spacing: -0.66px;
    margin: $margin-lg 0;
  }

  img {
    height: 7em;
  }

  &.stripe-top {
    border-top: 10px solid $volo-secondary;
  }
}
