@use 'sass:math';

@import '~/src/styles/_variables.scss';

$toggle-width: 34px;
$toggle-height: 14px;
// $toggle-height: math.div($toggle-width, 2) + 2;
$false-color: $grey-300;

.fancyBool {
  input {
    position: relative;
    border: none;
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    outline: none;
    margin-bottom: -4px;
    margin-right: 5px;
  }

  &.readonly {
    pointer-events: none;
    input,
    .toggleWrap {
      opacity: 0.7;
    }
  }

  &.disabled {
    pointer-events: none;
    color: $input-disabled-color !important;
  }

  &.checkbox {
    input {
      &:focus:after {
        border-color: $primary;
      }

      &:after {
        content: '';
        display: block;
        width: 18px;
        height: 18px;
        margin-top: -2px;
        border: 1px solid $false-color;
        border-radius: 2px;
        @include transition(240ms);
      }

      &:checked:after {
        background-color: $primary;
        border-color: $primary;
      }

      &:disabled:after {
        border-color: $grey-200;
      }

      &:disabled:checked:after {
        background-color: $grey-200;
        border-color: $grey-200;
      }

      &:checked:before {
        content: '';
        position: absolute;
        top: 0;
        left: 6px;
        display: table;
        width: 6px;
        height: 12px;
        border: 2px solid #fff;
        border-top-width: 0;
        border-left-width: 0;
        @include rotate(45deg);
      }
    }

    &.small input {
      &:after {
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        margin-top: -2px;
      }

      &:checked:before {
        content: '';
        position: absolute;
        top: 0px;
        left: 5px;
        display: table;
        width: 6px;
        height: 9px;
        border: 2px solid #fff;
        border-top-width: 0;
        border-left-width: 0;
        @include rotate(42deg);
      }
    }
  }

  &.radio {
    input {
      &:after {
        content: '';
        display: block;
        width: 18px;
        height: 18px;
        margin-top: -2px;
        border: 1px solid $false-color;
        @include transition(240ms);
        border-radius: 50%;
        background-size: 8px;
        background-repeat: no-repeat;
        background-position: center;
      }

      &:checked:after {
        background-color: transparent;
        border-color: $false-color;
      }

      &:checked:before {
        content: '';
        display: block;
        position: absolute;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: $primary;
        top: 1px;
        left: 3px;
        border: none;
      }

      &:disabled:checked:before {
        background-color: $input-disabled-color;
      }
    }
  }

  &.error {
    input {
      &:checked:after {
        background-color: $danger;
        border-color: $danger;
      }
    }
    .label {
      color: $danger;
    }
  }

  &.toggle {
    input {
      display: none;
      &:checked + .toggleWrap {
        border: 1px solid $rivall-navy;
        background-color: $sky-blue;
        .toggler {
          border-color: $rivall-navy;
          left: math.div($toggle-width, 2) - 3;
          top: -4px;
        }
      }
      &:disabled + .toggleWrap {
        opacity: 0.5;
      }
    }
  }

  .toggleWrap {
    display: inline-block;
    overflow: visible;
    position: relative;
    margin-bottom: -4px;
    margin-right: 5px;
    cursor: pointer;

    width: $toggle-width;
    height: $toggle-height;
    border-radius: $toggle-height;
    border: 1px solid $rivall-navy;

    .toggler {
      transition: all 0.1s linear;
      display: block;
      position: relative;
      top: -4px;
      left: -4px;

      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 1px solid $rivall-navy;
      background-color: $rivall-navy;
    }
  }

  $switch-height: 30px;
  $switch-br: 30px;
  &.switch {
    display: inline-block;
    width: auto;
    height: $switch-height;
    line-height: $switch-height - 2;

    .switch {
      display: inline-block;
      text-align: center;
      width: 200px;
      height: $switch-height;
      cursor: pointer;
      &.wide {
        width: 300px;
      }
    }

    .switchOption {
      display: inline-block;
      vertical-align: top;
      width: 50%;
      height: $switch-height;
      font-size: 14px;
      @include transition(0.2s);

      // falsy
      &.true {
        border-top-left-radius: $switch-br;
        border-bottom-left-radius: $switch-br;
        border-right: none;
        border: 1px solid $lightish-neutral;
        color: $med-neutral;
        border-right: none;
        background: white;
      }

      // truthy
      &.false {
        background: $grey-800;
        border: 1px solid $grey-800;
        border-left: none;
        color: white;
        border-top-right-radius: $switch-br;
        border-bottom-right-radius: $switch-br;
      }
    }

    input[type='checkbox'] {
      display: none;

      &:checked + .switch {
        .switchOption.true {
          background: $grey-800;
          border: 1px solid $grey-800;
          border-right: none;
          color: white;
        }
        .switchOption.false {
          background: white;
          border: 1px solid $lightish-neutral;
          border-left: none;
          color: $med-neutral;
        }
      }
      &:disabled + .switch {
        opacity: 0.5;
        cursor: default !important;
        pointer-events: none;
      }
    }

    &.wide {
      .switch {
        width: 400px;
        font-size: 13px;
      }
    }
  }
}
