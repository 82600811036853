@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.dropdown-wrap {
  .trigger {
    cursor: pointer;
    padding: 10px 0;
    border-radius: 2px;
    color: $text-color;
    margin: 2px 0;

    h2 {
      font-weight: 400;
      font-size: 18px;
    }

    img,
    h2 {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .dropdown-detail {
    border-bottom: 1px solid $border-color;
    padding: 0px;
    overflow: hidden;
    @include transition(0.3s);
    &.open {
      border-color: transparent;
    }
  }

  &.simple {
    .trigger {
      background-color: transparent;
      padding: 10px 0;
    }
  }

  @media screen and (max-width: $tablet-width) {
    .trigger {
      // padding: 5px 0;
    }
    .trigger h2 {
      font-size: 16px;
    }
  }
}
