@import '~/src/styles/_variables.scss';
@import '~/src/styles/_mixins.scss';

$color-indicator-width: 10px;
$color-indicator-margin: 5px;

.league-roster-detail {
  text-align: left;
  .toggle-show-players {
    @include emph;
    display: inline-block;
    border-radius: 20px;
    margin-bottom: 10px;
    padding: 3px 10px;
  }
  .hidden {
    display: none;
  }
  .key {
    font-size: 12px;
    color: $med-neutral;
  }
  .roster-item-wrap {
    width: 100%;
    padding: 10px;
    &:nth-child(2n) {
      background-color: $white-neutral;
    }
    &.header-row {
      font-size: 0.75em;
      background-color: #dadcdc;
      .record {
        font-weight: 700;
      }
    }
    .main-row {
      @include flex;
      line-height: 1.5;
    }
    .left,
    .right {
      @include flex-item;
      p {
        @include emph;
        font-size: 14px;
      }
    }
    .left {
      @include flex-item(0, 1, 400px);
      cursor: pointer;
      &:hover {
        h2 {
          font-weight: 600;
        }
      }
      h2 {
        padding: 0;
        margin: 0;
        font-size: 14px;
        font-weight: 300;
        display: inline-block;
        vertical-align: bottom;
        .vp-img {
          padding-left: 5px;
          height: 18px;
          position: relative;
          top: 2px;
        }
      }
      .color-indicator {
        display: inline-block;
        vertical-align: middle;
        margin-right: $color-indicator-margin;
        width: $color-indicator-width;
        height: $color-indicator-width;
        border-radius: 50%;
        line-height: 1.5;
      }
      .team-color {
        color: $lightish-neutral;
        margin-right: 4px;
        font-size: 10px;
      }

      a {
        @include emph;
        margin-left: 5px;
      }
    }

    .right {
      .record {
        display: inline-block;
        width: 60px;
        margin: 0 5px;
        text-align: center;
      }
    }

    .detail {
      display: block;
      overflow: hidden;
      padding-left: $color-indicator-width + $color-indicator-margin;
      max-height: 0px;
      &.shown {
        max-height: 300px;
        padding-top: 5px;
        padding-bottom: 5px;
        max-height: 100%;
      }
      p,
      ul {
        font-size: 12px;
        line-height: 1.5;
      }
      .player-list {
        .table-col {
          font-size: 12px;
          font-weight: 300;
          padding: 5px;
          &.center {
            text-align: center;
          }
        }
      }

      ul {
        list-style-type: none;
      }
    }

    .color {
      color: $med-neutral;
    }
    .forfeits {
      color: $error;
      font-weight: 600;
    }
  }
}

.points-formula {
  font-size: 0.9em;
  color: #666;
  margin: 20px 0 10px;
}

.points-formula-header {
  font-size: 1.1em;
  margin-bottom: 3px;
}
