@import '~/src/styles/_mixins.scss';
@import '~/src/styles/_variables.scss';

.color-indicator {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  border: 1px solid white;
  font-size: 0;
  line-height: 1;
  margin-right: 4px;
  vertical-align: middle;
}
