@import '~/src/styles/_variables';

// custom react-alert styles
.alertTemplate {
  position: relative;
  padding: 15px 20px;
  background-color: white;
  @include ev-8;
  border-radius: 3px;
  font-weight: 500;
  display: inline-block;
  float: right;
  .closeX {
    -webkit-appearance: none;
    cursor: pointer;
    border: 0;
    outline: 0;
    position: absolute;
    top: 6px;
    right: 13px;
    background-color: transparent;
    line-height: 0;
    z-index: 3;
    color: $grey-400;
    font-size: 20px;
    height: 0;
    width: 0;
    display: flex;
    align-items: flex-start;
    &:hover {
      color: $grey-500;
    }
  }
  &.error {
    background-color: $danger;
    color: white;
    .closeX {
      color: white;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  &.success {
    background-color: darken($success, 5%);
    color: white;
    .closeX {
      color: white;
      &:hover {
        opacity: 0.8;
      }
    }
  }
}
