@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.account-form {
  p {
    font-size: 14px;
  }
}
.other-forms {
  div {
    border-bottom: 1px solid $border-color;
    display: flex;
    justify-content: space-between;
    font-weight: 300;
    text-align: left;
    padding: 10px;
    display: flex;
    align-items: center;
    color: $rivall-navy;
    font-weight: 700;
    cursor: pointer;
    &:last-child {
      border: none;
    }
    &:hover {
      background-color: $bg-light;
    }
  }
}

.form-header {
  img {
    height: 32px;
  }
}

.profile-picture-header {
  img {
    height: 5rem;
  }
}

.settings-modal {
  text-align: left;
  display: flex;
  flex-direction: column;
}

.settings-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 3rem;
}

.back-arrow {
  color: $rivall-navy;
  font-size: 1.5rem;
  font-weight: 700;
  text-align: left;
  position: absolute;
  width: 100%;
  top: 1.6rem;
  left: 3.2rem;
  cursor: pointer;
}

h4 {
  color: $rivall-navy;
  font-size: 1rem;
  text-align: left;
  width: 100%;
}

.save-button {
  background-color: transparent;
  border-color: $lighter-neutral;
  color: $lighter-neutral;
  padding: 4px 0;
  width: 260px;
  margin: 20px auto 5px;
  max-width: 100%;
  pointer-events: none;
  cursor: default;
  text-transform: none;
  display: block;
  &.valid {
    @include button($dark-neutral);
    display: block;
    pointer-events: auto;
    cursor: pointer;
  }
}

.close-button,
.cancel-button {
  @include emph;
  @include link($med-neutral);
  display: inline-block;
  font-size: 12px;
  font-weight: 200;
  margin: 10px auto 0;
}

.choice-button {
  display: inline-block;
  @include ghost-button($dark-neutral);
  width: 120px;
  margin: 20px 30px;
  &.active {
    background-color: $main;
    color: $dark-neutral;
  }
}

.fields-wrap {
  text-align: left;
  max-width: 260px;
  margin: 10px auto;
  label {
    display: block;
    margin: 0 0 5px 5px;
    font-weight: 500;
    line-height: 16px;
    font-size: 14px;
    letter-spacing: 0.2px;
    text-transform: uppercase;
  }
}

.account-form {
  .label {
    font-weight: 600;
    text-align: right;
    display: inline-block;
    padding-right: 10px;
    width: 80px;
    vertical-align: middle;
  }
}

.profile-form {
  .choice-button {
    width: auto;
    max-width: 120px;
    padding: 2px 4px;
    margin: 5px;
    line-height: 1.2;
  }

  .tshirt-wrap .choice-button {
    @include ghost-button($dark-neutral);
  }
}

.card-form-wrap {
  border: 1px solid $med-neutral;
  border-radius: 3px;
  padding: 5px 0 5px 5px;
}
.payment-form.is-loading {
  .card-form-wrap {
    display: none;
  }
}
.payment-methods {
  margin-bottom: 30px;
  .source {
    margin: 5px auto;
  }
  p {
    display: inline-block;
    &.default-indicator {
      margin-left: 10px;
      font-weight: 600;
      font-style: italic;
      font-size: 12px;
      color: $main;
    }
  }
  .set-default {
    display: inline-block;
    margin-left: 10px;
    font-weight: 600;
    font-size: 12px;
  }
}

.phone-form {
  .reset-token {
    color: red;
    display: inline-block;
    font-size: 12px;
    font-weight: 600;
    margin-top: 10px;
  }
  .current {
    p {
      margin: 10px auto 0;
      &.verified {
        margin: 5px auto 10px;
        b {
          color: $main;
        }
        &.unverified {
          b {
            color: red;
          }
        }
      }
    }
  }
  .edit-phone {
    @include emph;
    font-size: 14px;
  }
}

.notifications-form {
  i {
    color: $med-neutral;
  }
  .fields-wrap {
    margin-top: 10px;
  }
  p {
    font-size: 14px;
  }
}

.privacy-form {
  margin-top: 10px;
  .cancel-button {
    margin-top: 30px;
  }
}

@media (max-width: 595px) {
  .back-arrow {
    top: 1rem;
    left: 2.2rem;
  }
}
