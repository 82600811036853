@import '~/src/styles/_variables';

.overflowMenu {
  position: relative;
  display: inline-block;
  line-height: 1;
}

button.trigger {
  padding: 5px 6px;
  box-shadow: none;
  background-color: transparent;

  // Styles specific to the player dashboard
  &[data-player-dashboard='true'] {
    border-radius: 50%;
    padding: 0.1em;

    // Don't animate. None of the other buttons have animations, so having them here would be jarring.
    transition: none;

    // Don't try to use a different color gear, as the other buttons don't do this
    .iconSettings:hover {
      background-image: url('../../images/icons/settings-inactive.svg');
    }

    &:hover {
      // Match the appearance of the other buttons when hovered
      background-color: #e6eaee;
      border-color: transparent;
    }
  }
}

.dot {
  height: 4px;
  width: 4px;
  background-color: $text-color;
  border-radius: 50%;
  margin: 0 2px;
  display: inline-block;
}

.small .dot {
  height: 3px;
  width: 3px;
}

.largeDots .dot {
  height: 5px;
  width: 5px;
}

.icon {
  display: inline-block;
  width: 26px;
  height: 26px;
  background-size: cover;
}

.iconSettings {
  background-image: url('../../images/icons/settings-inactive.svg');
  &:hover {
    background-image: url('../../images/icons/settings-active.svg');
  }
}

@mixin menuPosition($buttonSize: 30px) {
  &.bottomLeft {
    top: $buttonSize;
    right: 0;
  }
  &.bottomRight {
    top: $buttonSize;
    left: 0;
  }
  &.topLeft {
    bottom: $buttonSize;
    right: 0;
  }
  &.topRight {
    bottom: $buttonSize;
    left: 0;
  }
}

.menu {
  position: absolute;
  display: block;
  z-index: 10;
  background-color: white;
  border: 1px solid $border-color;
  @include ev-2;
  min-width: 250px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s;
  line-height: 1.4;

  &.visible {
    pointer-events: auto;
    opacity: 1;
  }

  @include menuPosition;
}

.menuItem {
  display: block;
  padding: 10px 12px;
  font-size: 13px;
  border-bottom: 1px solid $border-color;
  cursor: pointer;
  color: $text-color;
  text-align: left;
  transition:
    background-color 0.2s,
    color 0.2s;
  &:last-child {
    border: none;
  }
  &:hover,
  &a:hover {
    background-color: $primary;
    color: white;
    text-decoration: none;
    *,
    .helpText {
      color: white !important;
    }
  }
  &.disabled {
    pointer-events: none;
    color: $input-disabled-color;
  }

  &.withHelpText {
    font-weight: 700;
  }

  .helpText {
    font-weight: 400;
    line-height: 1.4;
    margin-top: 2px;
    color: $text-muted;
  }
  .itemIcon {
    display: inline-block;
    padding-right: 3px;
    min-width: 23px;
  }

  // indent evenly
  &.withIcon.withHelpText {
    .helpText {
      margin-left: 23px;
    }
  }
}

.large {
  .trigger {
    padding: 10px;
  }
  .menu {
    @include menuPosition(40px);
    min-width: 300px;
    .menuItem {
      padding: 12px 18px;
    }
  }
}

.small {
  .trigger {
    padding: 10px;
  }
  .menu {
    font-size: 12px;
    min-width: 200px;
    height: 500px;
    overflow: scroll;
    .menuItem {
      padding: 8px 10px;
    }
  }
}

.verticalDots {
  .dot {
    display: block;
    margin: 1px 0 0;
    &:first-child {
      margin-top: 0;
    }
  }
  &.largeDots {
    .dot {
      margin: 2px 0 0;
      &:first-child {
        margin-top: 0;
      }
    }
  }
}

.greyLines {
  .dot {
    background-color: $grey-500;
    display: block;
    width: 12px;
    height: 1px;
    margin: 3px 0;
    border-radius: 0;
  }
}
