.text-flash {
  animation-name: textflash;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.opacity-flash {
  animation-name: opacityflash;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.page-title {
  font-size: 1.5rem;
  font-weight: 500;
}

.label {
  display: block;
  font-size: 13px;
  font-weight: 500;
}

.overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-wait {
  cursor: wait !important;
}

.pointer-events-none {
  pointer-events: none !important;
}

.hover-opacity {
  transition: 0.3s opacity;
  opacity: 1;
  &:hover {
    opacity: 0.7;
  }
}

.font-weight-semibold {
  font-weight: 500 !important;
}

.border-bottom {
  border-bottom: 1px solid $border-color;
}
.border-top {
  border-top: 1px solid $border-color;
}
.border-left {
  border-left: 1px solid $border-color;
}
.border-right {
  border-right: 1px solid $border-color;
}

.dropin-badge {
  white-space: pre;
  padding: 2px 10px;
  border-radius: 30px;
  background-color: $dropin !important;
  border: 1px solid $dropin !important;
  color: white;
  display: inline-flex;
  align-items: center;
  line-height: 1.5;
  font-weight: 500;
  a {
    color: white;
    text-decoration: underline;
    &:hover {
      opacity: 0.8;
    }
  }
}

.text-dropin {
  color: $dropin;
}

.text-action {
  color: $action !important;
}

.small {
  font-size: smaller;
}

.currencyInput {
  background-color: #f7f7f7;
  padding: 5px;
  border: 1px solid #f7f7f7;
  padding: 0.625rem 0.625rem;
  border-radius: 100px;
  &.focused {
    color: $primary;
  }
}

.btn-brand-primary {
  background-color: #0a3355;
  color: #fff !important;
}
