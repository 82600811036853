@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.anon-nav {
  display: block;
  text-align: right;
  @include flex-item(1, 0);

  a {
    display: inline-block;
    font-size: 14px;
    color: white;
  }

  .signup {
    color: white;
  }
}
