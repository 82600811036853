@import '~/src/styles/_variables.scss';
@import '~/src/styles/_mixins.scss';

.handle-wrap {
  pointer-events: none;
  cursor: default;
  position: relative;
  width: 60px;
  left: -23px;
  bottom: 16px;
  text-align: center;
  p {
    font-size: 12px;
    font-weight: 500;

    &.with-label {
      width: 110px;
      margin-left: -25px;
      // margin-top: 30px;
      margin-top: -20px;
    }
  }
}

p.mark-label {
  text-align: center;
  pointer-events: none;
  cursor: default;
  font-size: 12px;
  position: relative;
  bottom: 32px;
  white-space: pre;
}

.size-handle {
  pointer-events: none;
  cursor: default;
  position: relative;
  width: 60px;
  left: -22px;
  top: 20px;
  &:before {
    content: '⌃';
    top: 1px;
    left: 50%;
    margin-left: -10px;
    position: absolute;
  }
  p {
    padding-top: 10px;
    font-size: 12px;
    font-weight: 600;
    font-style: italic;
  }
}

.static-range {
  pointer-events: none;
}

.slider-wrap {
  label {
    padding: 0 !important;
    padding-top: 8px !important;
  }
}
