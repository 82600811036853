@import '../../styles/_mixins.scss';
@import '../../styles/_variables.scss';

.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.zindex {
  z-index: 1039 !important;
}

.popup-component {
  @include popup(500px);
  text-align: center;
  position: fixed;
  top: 10vh;
  max-height: 80vh;
  overflow-y: scroll;
  border-radius: 3px;
  @include ev-4;

  .close-x {
    @include close-x;
    width: 1.5em;
    height: 1.5em;
    font-size: 2em;
    color: $text-muted;
    z-index: inherit;
  }

  &.small {
    @include popup(400px);
    position: fixed;
    top: 8vh;
    max-height: 84vh;
    overflow-y: scroll;
    padding: 0 0 15px 0;
  }

  &.middle {
    @include popup(600px);
    position: fixed;
    top: 8vh;
    max-height: 84vh;
    overflow-y: scroll;
  }

  &.wide {
    @include popup(680px);
    position: fixed;
    top: 8vh;
    max-height: 84vh;
    overflow-y: scroll;
  }

  &.wider {
    @include popup(720px);
    position: fixed;
    top: 8vh;
    max-height: 84vh;
    overflow-y: scroll;
  }

  &.widest {
    @include popup(800px);
    position: fixed;
    top: 8vh;
    max-height: 84vh;
    min-height: 50vh;
    overflow-y: scroll;
  }

  &.max {
    @include popup($max-container-width);
    position: fixed;
    top: 8vh;
    max-height: 84vh;
    overflow-y: scroll;
  }

  &.full {
    @include popup(90vw);
    position: fixed;
    top: 8vh;
    max-height: 84vh;
    overflow-y: scroll;
  }

  &.main {
    background-color: $main;
  }

  &.full-page {
    top: 45px;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    margin: 0;
    overflow-y: scroll;
  }

  // &.fixed {
  // }

  > a {
    @include link($med-neutral);
    font-size: 12px;
  }
}

@media screen and (max-width: 600px) {
  .popup-component {
    padding: 20px 15px;
    // &.fixed {
    top: 5vh;
    max-height: 90vh;
    overflow-y: scroll;
    // }
  }
}
