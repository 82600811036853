@use '../../abstracts/' as *;
@use '../../abstracts/registerVariables' as rv;

.card-stack {
  @mixin card-defaults {
    color: $volo-primary;

    h2 {
      margin-bottom: 0.5rem;
    }

    h3 {
      font-size: 1.25rem;
      font-weight: 100;
      letter-spacing: 1px;
    }

    p {
      letter-spacing: -0.1px;
      line-height: 1.1rem;
    }
  }
  // private variables
  $web-box-height: 220px;
  $web-box-width: 215px;

  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
  background-color: $volo-secondary-light;

  .mini-card {
    @include card-defaults;
    border-radius: 4px;
    padding: 1rem;
    background-color: white;
    border: 1px solid $volo-secondary;
    margin: 0.5rem;
    height: $web-box-height;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: scroll;

    p {
      margin-top: 1rem;
    }

    .member-pricing {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    h3 {
      display: flex;
      align-items: flex-end;
      margin-top: 0 !important;
    }

    h4 {
      font-weight: 400;
      text-decoration: line-through;
      margin-left: 0.3rem;
    }

    .member-pricing-box {
      text-align: center;
      color: #fff;
      background-color: $rivall-blue-300;
      border-radius: 4px;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 9px;
      padding: 0.4rem 0.3rem;
      margin: 0;
      max-width: 120px;
      position: absolute;
      right: 0;
      bottom: 0;
    }

    &:hover {
      cursor: pointer;
    }

    &.selected {
      &::after {
        content: url(../../../../images/icons/checkmark-circle.svg);
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }

      &::before {
        content: '';
        border-radius: 4px;
        background-color: $volo-secondary;
        color: white;
        position: absolute;
        top: 0;
        left: 0;
        height: calc(100% - 1rem);
        width: calc(100% - 1rem);
        opacity: 0.3;
        margin: 0.5rem;
      }
    }
  }

  .card {
    @include card-defaults;
    border-radius: 4px;
    background-color: white;
    border: 1px solid $volo-secondary;
    padding: 1rem;
    margin: 0.5rem;
    display: flex;
    align-items: center;

    h3 {
      font-size: 1rem;
    }

    &:hover {
      cursor: pointer;
    }

    &.selected {
      &::after {
        content: url(../../../../images/icons/checkmark-circle.svg);
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }
      &::before {
        content: '';
        border-radius: 4px;
        background-color: $volo-secondary;
        color: white;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.3;
        margin: 0.5rem;
        height: calc(100% - 1rem);
        width: calc(100% - 1rem);
      }
    }
  }

  @media (min-width: rv.$breakpoint-lg) {
    align-content: space-between;
    justify-content: space-between;
    padding: 0;
    background-color: white;

    .card {
      @include card-defaults;
      background-color: $volo-secondary-light;
      width: 100%;
      margin-bottom: 0.7rem;
      padding: 1rem;
      display: flex;
      align-items: center;
      border: 0;

      .small-header {
        font-size: 1rem;
      }

      &.selected {
        &::before {
          height: calc(100% - 1rem);
          width: 100%;
        }
      }
    }

    .mini-card {
      @include card-defaults;
      background-color: $volo-secondary-light;
      border: 0;
      height: $web-box-height;
      width: $web-box-width;
      padding: 1.5rem;
      overflow: scroll;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: 0.5rem;

      h3 {
        margin-top: 1rem;
      }

      &.selected {
        &::after {
          height: $web-box-height;
          width: $web-box-width;
          margin: 0.5rem;
        }
        &::before {
          height: $web-box-height;
          width: $web-box-width;
          margin: 0.5rem;
        }
      }
    }
  }
}
