@import '~/src/styles/_variables.scss';

.info-container {
  white-space: pre-wrap;
  position: relative;
  .title {
    display: inline;
    position: absolute;
    top: 3px;
    left: 35px;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
  }
  .safety {
    margin: 8px 0;
    font-size: 14px;
  }
  .week-tag {
    // border: 1px solid gray;
    background-color: white;
    padding: 2px 6px;
    border-radius: 3px;
    img {
      height: 15px;
      padding-top: 5px;
      padding-right: 5px;
      margin: 0 !important;
      width: 15px !important;
    }
  }
  .icon {
    width: 26px;
    height: 26px;
    margin: 0;
  }
  .features {
    position: relative;
    padding-top: 12px;
    img {
      height: 30px;
      width: auto;
      margin: 0;
      position: absolute;
      left: 0;
    }
    span {
      padding-left: 22px;
      display: inline-block;
      padding-top: 8px;
      min-height: 18px;
    }
  }
}
.gist {
  overflow: scroll;
}
