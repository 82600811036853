@use '../../abstracts' as *;

.sourceItem {
  border-radius: 3px;
  .current {
    font-size: smaller;
    margin-bottom: 8px;
    margin-top: -3px;
    font-weight: 500;
  }
  .exp {
    color: $text-muted;
  }
  &.isExpired {
    .exp {
      color: $danger;
    }
  }
}

.brand {
  height: 40px;
  width: 60px;
  background-color: $grey-200;
  border-radius: 3px;
  margin-right: 10px;
  &.visa {
    background-image: url('../../../../images/payment-brands/1.png');
    // background-image: url("../../../../images/payment-brands/1.png");
  }
  &.mastercard {
    background-image: url('../../../../images/payment-brands/2.png');
  }
  &.discover {
    background-image: url('../../../../images/payment-brands/14.png');
  }
  &.americanexpress {
    background-image: url('../../../../images/payment-brands/22.png');
  }
  &.dinersclub {
    background-image: url('../../../../images/payment-brands/10.png');
  }
}
