@import '~/src/styles/_variables.scss';
@import '~/src/styles/_mixins.scss';

@mixin right-menu {
  @include flex-item(1, 0, auto, center);
  text-align: right;
  line-height: 1;
  // font-size: 0;
  @include media-breakpoint-down(xs) {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 14px;
    line-height: 1;
    pointer-events: none;
  }
}

header.header {
  width: 100%;
  position: fixed;
  z-index: 4;
  @include transition(0.2s);
  background-color: white;
  border-bottom: 1px solid $border-color;
  height: $header-height;
  display: flex;
  align-items: center;

  :global(.container) {
    @include flex(center);
    @include transition(0.2s);
    margin: 0 auto;
  }

  a {
    color: $dark-neutral;
  }

  .home-link {
    display: flex;
    align-items: center;
  }
  .logo-thumb {
    background-image: url('https://rivall-public.s3-us-west-1.amazonaws.com/home-page/volo-color-logo.png');
    background-image: -webkit-image-set(
      url('https://rivall-public.s3-us-west-1.amazonaws.com/home-page/volo-color-logo.avif') 1x,
      url('https://rivall-public.s3-us-west-1.amazonaws.com/home-page/volo-color-logo.webp') 1x,
      url('https://rivall-public.s3-us-west-1.amazonaws.com/home-page/volo-color-logo.png') 1x
    );
    background-image: image-set(
      url('https://rivall-public.s3-us-west-1.amazonaws.com/home-page/volo-color-logo.avif')
        type('image/avif'),
      url('https://rivall-public.s3-us-west-1.amazonaws.com/home-page/volo-color-logo.webp')
        type('image/webp'),
      url('https://rivall-public.s3-us-west-1.amazonaws.com/home-page/volo-color-logo.png')
        type('image/png')
    );
    background-size: contain;
    height: 18px;
    width: 40px;
  }
  .bar {
    display: block;
    width: 1px;
    height: 26px;
    background-color: $grey-300;
    margin: 0 10px;
  }
  .org-logo {
    height: 26px;
  }

  // scrolling styles
  .scrolling {
    background-color: rgba(255, 255, 255, 0.9);
    @include ev-2;
  }
}

@include media-breakpoint-down(xs) {
  header.header {
    .container {
      display: static;
      padding: 10px;
    }
    h1 {
      margin: 0 auto;
    }
    .scrolling {
      background-color: transparent;
      border: none;
      box-shadow: none;
      .left a {
        opacity: 0;
        pointer-events: none;
      }
    }
  }
}

.city-select {
  width: 12em;
  margin-left: 1em;
}
