@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

@keyframes blink {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

.inline {
  display: inline-flex;
  align-items: center;
}
.inline span {
  font-size: 10px;
  animation-name: blink;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  margin-bottom: 1px;
  color: $text-muted;
  &:nth-child(2) {
    animation-delay: 0.2s;
  }
  &:nth-child(3) {
    animation-delay: 0.4s;
  }
}

.loader {
  width: 30px;
  // &.basketball {
  //   width: 200px;
  // }
}

.simple-loader {
  @include animation(loader-animation 700ms infinite linear);
  border: 1px solid $lighter-neutral;
  border-radius: 50%;
  border-top-color: darken($main, 20%);
  display: block;
  margin: 0 auto;
  height: 20px;
  width: 20px;
}

.page-loader {
  text-align: center;
}

img.page-loader {
  display: block;
  text-align: center;
  margin: 10% auto 10px;
  width: 30px;
  &.soccer {
    margin-bottom: 8px;
  }
}

img.inline {
  display: inline-block;
  vertical-align: middle;
  height: 6px;
  width: auto;
  margin: 0 4px;
}

img.fixed {
  position: fixed;
  left: 50%;
  width: 140px;
  margin-left: -70px;
  top: 20%;
  // @include box-shadow(0, 0, 50px, 30px, rgba(100, 100, 100, 0.2));
  // background: rgba(100, 100, 100, 0.2);
  // border-radius: 40px;
}

img.section {
  display: block;
  margin-top: 5px;
  width: 20px;
}

img.block {
  display: block;
  margin: 10px auto;
  width: 30px;
}

img.horizontal,
.horizontal-b,
.horizontal-w {
  width: 20px;
}

.loading h2 {
  font-size: 16px;
  line-height: 1.2;
  width: auto;
  margin: 0 auto;
  color: $main;
  font-style: italic;
  font-weight: 600;
  &.soccer {
    color: $dark-neutral;
  }
  &.white {
    color: white;
  }
  &.section {
    font-size: 12px;
  }
  &.page-loader {
    text-align: center;
  }
}

@keyframes loader-animation {
  to {
    transform: rotate(1turn);
  }
}
@-webkit-keyframes loader-animation {
  to {
    -webkit-transform: rotate(1turn);
  }
}

.page-loader-wrap {
  width: 100%;
}
