@use '../abstracts/' as *;

$league-overview-width: 850px;
$league-overview-margin: calc((100vw - #{$league-overview-width}) / 2);

:global(::-webkit-scrollbar) {
  display: none;
}

.league-overview {
  margin: $margin-lg;
  white-space: pre-wrap;

  section {
    margin: 1rem auto 2.5rem auto;
  }

  h1 {
    font-size: 1.1rem;
    font-weight: bold;
    letter-spacing: -0.25px;
    margin: 0.25rem auto;
    display: flex;
    align-items: center;
  }

  h2 {
    color: $neutral-09;
    font-size: 0.9rem;
    letter-spacing: -0.25px;
    display: flex;
    align-items: center;
    font-weight: normal;

    &.selected {
      border-bottom: 2px solid $volo-accent;
    }

    &.bold {
      font-weight: bold;
    }

    .icon {
      height: 100%;
      margin-right: 0.5rem;
    }
  }

  p {
    align-items: center;
    font-size: 0.8rem;
    margin: 0.5rem auto auto auto;
    overflow: inherit;

    .perk-icon {
      height: 1.25rem;
      margin-right: 1rem;
    }
  }

  .league-header {
    .league-header-banner {
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      height: 400px;
      margin: 1rem auto;
      overflow: hidden;
      width: 100%;
      z-index: 0;
      position: relative;

      justify-content: flex-end;
      align-items: flex-start;
      background-color: #2582d4;
      background-image: url('https://rivall-public.s3.us-west-1.amazonaws.com/program-fallback-images/bg-pattern_tile.png');
      background-image: -webkit-image-set(
        url('https://rivall-public.s3.us-west-1.amazonaws.com/program-fallback-images/bg-pattern_tile.avif')
          1x,
        url('https://rivall-public.s3.us-west-1.amazonaws.com/program-fallback-images/bg-pattern_tile.webp')
          1x,
        url('https://rivall-public.s3.us-west-1.amazonaws.com/program-fallback-images/bg-pattern_tile.png')
          1x
      );
      background-image: image-set(
        url('https://rivall-public.s3.us-west-1.amazonaws.com/program-fallback-images/bg-pattern_tile.avif')
          type('image/avif'),
        url('https://rivall-public.s3.us-west-1.amazonaws.com/program-fallback-images/bg-pattern_tile.webp')
          type('image/webp'),
        url('https://rivall-public.s3.us-west-1.amazonaws.com/program-fallback-images/bg-pattern_tile.png')
          type('image/png')
      );
      background-size: 180px auto;
      background-position: top left;
      background-repeat: repeat;

      picture,
      img {
        position: relative;
        z-index: 1;
        width: 100%;
        min-height: 100%;
        object-fit: cover;
        object-position: center;
      }

      &:before {
        content: '';
        width: 120px;
        height: 60px;
        position: absolute;
        top: calc(50% - 30px);
        right: 25px;
        background-image: url('https://rivall-public.s3.us-west-1.amazonaws.com/program-fallback-images/vs-logo.png');
        background-image: -webkit-image-set(
          url('https://rivall-public.s3.us-west-1.amazonaws.com/program-fallback-images/vs-logo.avif')
            1x,
          url('https://rivall-public.s3.us-west-1.amazonaws.com/program-fallback-images/vs-logo.webp')
            1x,
          url('https://rivall-public.s3.us-west-1.amazonaws.com/program-fallback-images/vs-logo.png')
            1x
        );
        background-image: image-set(
          url('https://rivall-public.s3.us-west-1.amazonaws.com/program-fallback-images/vs-logo.avif')
            type('image/avif'),
          url('https://rivall-public.s3.us-west-1.amazonaws.com/program-fallback-images/vs-logo.webp')
            type('image/webp'),
          url('https://rivall-public.s3.us-west-1.amazonaws.com/program-fallback-images/vs-logo.png')
            type('image/png')
        );
        background-size: 100% auto;
        background-position: center right;
        background-repeat: no-repeat;
        mix-blend-mode: screen;
        z-index: 0;
      }

      .league-header-icon {
        border: 1px solid $border-color;
        height: 100px;
        width: 100px;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: start;
        background-color: white;
        position: absolute;
        z-index: 10;
        bottom: 20px;
        left: 40px;

        img {
          width: 100%;
        }
      }
    }
  }

  .league-summary {
    .title {
      min-height: 2.5rem;
      display: flex;
      align-items: flex-start;

      &:hover {
        border-bottom: 2px solid $volo-accent;
        cursor: pointer;
      }
    }
  }

  .league-cta {
    display: block;
    position: sticky;
    z-index: 999;
    .cta-card {
      border-radius: 4px;
      padding: 10px;

      .cta-banner {
        background-color: $volo-accent;
        color: $volo-primary;
        font-size: 1rem;
        font-weight: bold;
        padding: $padding-sm;
        text-align: center;
      }
    }
  }

  .league-skill-levels {
    .skill {
      font-weight: bold;
      color: $volo-primary;
    }

    p {
      margin-bottom: 0;
    }
  }

  .league-perks {
    .league-perks-chart {
      border: 1px solid $neutral-03;
    }
  }

  .league-schedule {
    .league-schedule-chart {
      display: flex;
      flex-direction: column;
    }
  }

  .league-registration-types {
    .ways-to-register {
      display: flex;
      flex-wrap: wrap;
      margin-top: 1.5rem;

      .ways-to-register-card {
        padding: $padding-md;
        border-radius: 4px;
        color: $volo-primary;

        .registerButton {
          position: absolute;
          bottom: 30px;
        }
      }
    }
  }

  .league-footer {
    .footer-links-group {
      display: flex;
      flex-wrap: wrap;
      margin-top: 1rem;
      justify-content: center;

      .footer-link {
        height: 6rem;
        width: 9rem;
        padding: 0.25rem;

        .footer-link-title {
          text-align: center;
          color: $volo-primary;
          font-weight: bold;
          white-space: nowrap;
        }
      }
    }

    .footer-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      > img {
        margin-top: 3rem;
        margin-bottom: 1rem;
        width: 10rem;
      }

      .org-info {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        &:before {
          content: '';
          width: 120px;
          height: 60px;
          position: absolute;
          top: calc(50% - 30px);
          right: 25px;
          background-image: url('https://rivall-public.s3.us-west-1.amazonaws.com/program-fallback-images/vs-logo.png');
          background-image: -webkit-image-set(
            url('https://rivall-public.s3.us-west-1.amazonaws.com/program-fallback-images/vs-logo.avif')
              1x,
            url('https://rivall-public.s3.us-west-1.amazonaws.com/program-fallback-images/vs-logo.webp')
              1x,
            url('https://rivall-public.s3.us-west-1.amazonaws.com/program-fallback-images/vs-logo.png')
              1x
          );
          background-image: image-set(
            url('https://rivall-public.s3.us-west-1.amazonaws.com/program-fallback-images/vs-logo.avif')
              type('image/avif'),
            url('https://rivall-public.s3.us-west-1.amazonaws.com/program-fallback-images/vs-logo.webp')
              type('image/webp'),
            url('https://rivall-public.s3.us-west-1.amazonaws.com/program-fallback-images/vs-logo.png')
              type('image/png')
          );
          background-size: 100% auto;
          background-position: center right;
          background-repeat: no-repeat;
          mix-blend-mode: screen;
          z-index: 0;
        }

        .league-header-icon {
          border: 1px solid $border-color;
          height: 100px;
          width: 100px;
          border-radius: 50%;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          align-self: flex-start;
          background-color: white;
          position: absolute;
          z-index: 10;
          bottom: 20px;
          left: 40px;

          img {
            width: 100%;
          }
        }
      }
    }

    .policy-html {
      h2 {
        text-align: center;
        width: 100%;
      }

      .popup-html {
        text-align: left;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    margin: $margin-lg $league-overview-margin;

    .league-cta {
      padding: $padding-sm;

      &.sticky {
        position: -webkit-sticky;
        position: sticky;
        top: 4rem;
      }
    }

    .league-summary {
      .title {
        font-size: 0.9rem;

        &:hover {
          border-bottom: 2px solid $volo-accent;
          cursor: pointer;
        }
      }
    }

    .league-perks {
      .league-perks-chart {
        display: flex;

        margin: 1rem 0;

        > :first-child {
          border-right: 1px solid $neutral-03;
        }
      }
    }

    .league-footer {
      .footer-links-group {
        flex-wrap: nowrap;
      }
    }
  }
}
