@import '~/src/styles/_variables.scss';
@import '~/src/styles/_mixins.scss';

.lgHeader {
  text-align: center;
  background-color: $primary;
  color: white;
  background-size: cover;
  // modify to fk with positioning of the image
  background-position: center;

  // to make the flash more chill
  transition:
    background-color 0.3s,
    background-image 0.3s;

  max-width: 1120px;
  margin: 0 auto;

  @media screen and (min-width: 1120px) {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  h1 {
    font-size: 36px;
    small {
      font-size: 16px;
      font-weight: 400;
    }
  }

  .content {
    width: 100%;
    min-height: 110px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    &.hasPhoto {
      min-height: 350px;
    }
  }
}

.orgItem {
  bottom: 1rem;
  position: absolute;
}

.iconWrap {
  border-radius: 50%;
  padding: 6px;
  border: 1px solid $border-color;
  display: flex;
}
.orgIcon {
  border-radius: 50%;
  margin-right: 5px;
  bottom: 20px;
  padding: 6px;
  border: 1px solid $border-color;
  > div {
    border-radius: 50%;
    background-color: white;
    height: 70px;
    width: 70px;
    background-size: contain;
    background-position: center;
  }
}

.hasPhoto {
  .orgIcon > div {
    height: 90px;
    width: 90px;
  }
}

@include media-breakpoint-down(md) {
  .orgItem {
    // margin-bottom: 20px;
    // text-align: left;
  }
  // .orgIcon {
  //   max-height: 70px;
  // }
  .lgHeader {
    .content {
      &.hasPhoto {
        min-height: 200px;
      }
    }
  }
}

.content {
  @include media-breakpoint-up(lg) {
    // > div {
    margin: 0 auto;
    max-width: $max-container-width-player;
    // }
  }
  section {
    h4 {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 8px;
    }
  }
}

.orgLogo {
  max-width: 220px;
  max-height: 70px;
}

.detail {
  border-bottom: 1px solid $border-color;
  img {
    width: 30px;
    margin-bottom: 6px;
  }
}
