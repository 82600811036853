// discover layout variables
$discover-body-height: calc(100vh - 72px);

$discover-header-height: 72px;
$discover-sidebar-width: 240px;
$discover-list-width-long: 704px;
$discover-list-width-short: 504px;
$discover-map-width: calc(100vw - 450px);

$discover-empty-space: calc(100vw - (#{$discover-sidebar-width} + #{$discover-list-width-long}));

$discover-margin: calc(#{$discover-empty-space} / 2);
