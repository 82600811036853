.jersey-container {
  .jersey-table {
    margin: 15px auto;
    border: 1px solid rgba(0, 0, 0, 0.12);
    .jersey-table-title {
      font-weight: bold;
    }
    th,
    td {
      padding: 8px 15px;
    }
    td {
      border: 1px solid rgba(0, 0, 0, 0.12);
    }
  }
  .color-indicator {
    margin-left: 5px;
    border-radius: 50%;
    display: inline-block;
    height: 10px;
    line-height: 1.5;
    vertical-align: middle;
    width: 10px;
  }
}
