@use '../../styles/volo/abstracts/' as *;
@import '~/src/styles/_variables.scss';

.playerDetails {
  font-size: 14px;
  a {
    &:hover {
      text-decoration: underline;
    }
  }
  label {
    text-transform: uppercase;
    color: darkgray;
  }
  .editName {
    font-size: 16px;
  }
  .defaultImg {
    height: 120px;
    width: 120px;
    line-height: 106px;
    margin-bottom: 10px;
    font-size: 40px;
    background: #d3d3d3;
    display: inline-block;
    padding-top: 9px;
    -moz-border-radius: 25%;
    border-radius: 25%;
  }
  .profilePic {
    display: inline-block;
    -moz-border-radius: 25%;
    border-radius: 25%;
    object-fit: cover;
    margin-bottom: 10px;
    height: 120px !important;
    width: 120px !important;
    float: none !important;
  }
  .headerList {
    text-transform: uppercase;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    span {
      width: 100%;
      border-bottom: 2px solid #e5e5e5;
      padding-bottom: 5px;
      color: #666666;
      &.activeTab {
        color: $primary;
        border-bottom: 2px solid $primary;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
  .editInfo {
    text-align: left;
    word-break: break-word;
    .noEdit div {
      margin: 5px 0 15px;
    }
    .phoneInput {
      width: 100%;
      line-height: 1;
      margin-bottom: 1rem !important;

      input {
        padding: 10px 10px;
        border-radius: 10px;
        border: none;
        background-color: #f7f7f7;
        background-position: right 5px center;
      }
    }
  }
  .imgWrap {
    &:hover {
      cursor: pointer;
    }
    img {
      height: 20px;
    }
  }
  .invoiceRow {
    line-height: normal !important;
    text-align: center;
    padding: 15px 5px;
    border-bottom: 1px solid #e7e7f0;
    .platform-icon {
      position: absolute;
      top: 0px;
      right: -6px;
    }
    .clickable:hover {
      color: $primary;
    }
    &:hover {
      background: #f5f5f5;
      cursor: pointer;
    }
  }
  .actionButton {
    background: transparent;
    border: 2px solid $primary;
    border-radius: 5px;
    color: $primary;
    text-transform: uppercase;
    &:hover {
      color: $primary;
    }
  }
  .paymentBox {
    padding: 20px;
    margin-bottom: 10px;
    border-radius: 8px;
    max-width: 167px;
    border: 2px solid #dfe0eb;
    h2 {
      color: #9fa1b4;
    }
    .regColor {
      color: black;
    }
    .greenColor {
      color: #7aaa2b;
    }
    .redColor {
      color: #db022a;
    }
  }
  .newNote {
    margin: 20px;
    text-align: left;
    button {
      float: right;
    }
    textarea {
      border: 1px solid #f0f1f7 !important;
      background-color: #fcfdfe !important;
      border-radius: 8px !important;
      min-height: 200px;
    }
  }
  .notePopup {
    margin: 15px 0;
    .noteInfo {
      text-align: left;
      padding: 10px;
      border: 1px solid #f0f1f7;
      background-color: #fcfdfe !important;
      border-radius: 8px !important;
      min-height: 200px;
    }
    .noteAuthor {
      float: right;
      margin-top: 5px;
    }
  }
  .noteList {
    button {
      margin-top: 15px;
      float: right;
    }
    .cutOff {
      text-overflow: ellipsis;
      overflow: hidden;
      padding-right: 5px;
      white-space: nowrap;
    }
  }
  .currencyInput {
    background-color: #f7f7f7;
    padding: 5px;
    border: 1px solid #f7f7f7;
    padding: 0.625rem 0.625rem;
    border-radius: 100px;
    &.focused {
      color: $primary;
    }
  }
  .selectContainer {
    border-radius: 8px;
    border: 2px solid #dfe0eb;
    padding: 15px 10px;
    .selectRow {
      padding: 0 5px !important;
    }
    small {
      text-align: center;
      display: block;
    }
  }
  .refund {
    background-color: #fef6f6;
  }
  .credit {
    background-color: #effff0;
  }
  .active,
  .inactive,
  .suspended {
    &:before {
      content: '';
      height: 10px;
      width: 10px;
      border-radius: 50%;
      display: inline-block;
      margin-right: 5px;
    }
  }
  .active::before {
    background: #99c354;
  }
  .inactive::before {
    background: #f2994a;
  }
  .suspended::before {
    background: #f85d5d;
  }
  .star {
    height: 20px !important;
    width: auto !important;
    position: relative !important;
    top: 3px !important;
    left: 2px !important;
  }
  .legend {
    margin-top: 15px;
    border: 1px solid #e9e9f1;
    border-radius: 3px;
    padding: 5px 0;
  }
  h3 {
    padding: 0 !important;
    margin: 30px 10px 8px !important;
    font-size: 16px !important;
    font-weight: bold !important;
    background-color: transparent !important;
  }
}

.profile-img-container {
  position: relative;
  height: 14px;
  width: 20px;
  display: block;
  .profile-img {
    position: absolute;
    top: -3px;
    &:hover {
      cursor: pointer;
    }
  }
}

.waiver-controls {
  p {
    font-size: 1rem;
    line-height: 1.33rem;
  }

  .waiver-status {
    img:hover {
      cursor: pointer;
    }
  }

  .waiver-actions {
    button {
      border: none;

      &:hover {
        border: none;
      }
    }
  }
}

.reports-strikes-and-incidents {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  margin-top: 75px;

  > .player-strikes {
    text-align: start; // A style above is centering it, so this resets the text to its correct state
  }
}

.isMember img {
  height: 25px;
  margin-left: 10px;
  margin-bottom: -5px;
}
