@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';

.schedule-wrap {
  text-align: left;
  margin: 10px auto;
  .week-item {
    padding: 5px;
    border-top: 1px dashed $lighter-neutral;
    &:first-child {
      border: none;
      padding-top: 0;
    }
  }
  .week-label {
    padding-bottom: 5px;
    margin: 10px 0;
    border-bottom: 1px solid $lighter-neutral;
    .week-num {
      @include caps;
      font-size: 1em;
      line-height: 1.3;
      font-weight: 600;
      display: inline-block;
    }
    .notes {
      font-style: italic;
      color: #979797;
      padding-top: 1px;
      display: inline;
      float: right;
    }
    .week-tag {
      border: 1px solid $primary;
      background-color: white;
      color: $primary;
      margin-left: 10px;
      padding: 2px 6px;
      border-radius: 3px;
      img {
        height: 15px;
        padding-top: 5px;
        padding-right: 5px;
      }
    }
  }
  .date {
    @include emph;
    margin-bottom: 4px;
  }
  .game-item {
    margin-bottom: 4px;
    position: relative;
    border-bottom: 1px solid $lighter-neutral;
    padding-bottom: 2px;
    p {
      font-size: 12px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      width: 20%;
      &.start-time {
        margin-right: 5px;
        width: 100px;
      }
      .team-color {
        color: $lightish-neutral;
        margin-right: 4px;
        font-size: 10px;
      }
      &.team-placeholder {
        &:last-child {
          padding: 0 0 0 8px;
          &:before {
            content: 'vs. ';
            position: absolute;
            color: $lightish-neutral;
            font-size: 10px;
            left: -8px;
            top: 2px;
          }
        }
        .team-name-score {
          img {
            height: 12px;
            width: auto;
            position: relative;
            top: 1px;
            margin-left: 4px;
          }
        }
        &.forfeit {
          .team-name-score {
            color: #f00;
          }
        }
        &.unscored {
          background-color: #f00;
          color: #fff;
          padding: 5px 0 5px 15px;
          margin: 0 15px 0 -15px;
        }
      }
      &.sub-location {
        padding: 0 0 0 8px;
        &:before {
          content: '@ ';
          position: absolute;
          color: $lightish-neutral;
          font-size: 12px;
          left: -8px;
          top: 0;
        }
      }
    }
    .color-indicator {
      display: inline-block;
      width: 8px;
      height: 8px;
      margin-right: 3px;
    }
    .edit-score-trigger {
      height: 18px;
      vertical-align: middle;
      margin-left: 5px;
      cursor: pointer;
    }
    .game-tools {
      position: absolute;
      top: 0;
      right: 10px;
    }
  }
  .bye-wrap {
    margin: 20px 0 0;
    h3 {
      font-size: 12px;
    }
    p {
      font-size: 12px;
      font-weight: 300;
      padding: 10px 0;
    }
  }
}

.score-input {
  width: 42px;
  display: inline-block;
  margin-right: 5px;
}

.forfeit-button {
  margin-top: 0.8em;
}

.edit-score-popup {
  h2 {
    font-size: 18px;
  }
  .team-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    font-weight: 300;
    font-size: 14px;
    margin-bottom: 1em;
    text-align: left;
  }
  .color-indicator {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 3px;
    vertical-align: top;
  }
  h3 {
    display: inline-block;
    font-weight: 300;
    font-size: 14px;
    vertical-align: top;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  > div {
    margin: 10px auto;
    padding: 10px;
    display: inline-block;
    width: 50%;
    vertical-align: top;
  }

  button {
    margin: 10px auto 0;
  }

  .save {
    @include button($dark-neutral);
    width: 260px;
    margin: 20px auto 0;
    &.disabled {
      @include ghost-button($light-neutral);
    }
  }
}

@media screen and (max-width: 500px) {
  .schedule-wrap {
    .game-item {
      .time {
        display: block;
        margin: 0;
      }
      .edit-score-trigger {
        height: 22px;
        vertical-align: middle;
        margin-left: 5px;
        cursor: pointer;
      }
    }
  }
  .edit-score-popup {
    h2 {
      font-size: 16px;
    }
    .team-name {
      font-size: 13px;
    }
    h3 {
      font-size: 13px;
    }

    > div {
      margin: 15px auto 0;
      padding: 0 10px;
      width: 100%;
      > div {
        margin: 0 auto;
      }
    }
  }
}
