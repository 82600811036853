@use '../abstracts/' as *;
@use './buttons' as *;

.view-toggles {
  .toggle-buttons-group {
    display: flex;
    justify-content: space-between;

    &.disabled {
      pointer-events: none;
    }

    .toggle-button {
      @include btn(#fff, $neutral-07, $volo-secondary);
      flex: 1 1;
      background-color: white;

      &.disabled {
        pointer-events: none;
        border-color: $neutral-02;
      }

      &:hover {
        -webkit-transition: background-color 0.25s linear;
        /* For Safari 3.1 to 6.0 */
        transition: background-color 0.25s linear;
      }

      &.selected {
        background-color: $volo-secondary;
        -webkit-transition: background-color 1s;
        /* For Safari 3.1 to 6.0 */
        transition: background-color 1s;
        color: white;
        font-weight: bold;
      }

      &.rounded {
        border-radius: 30px;
      }

      &:first-child {
        margin-left: 0px;
      }
      &:last-child {
        margin-right: 0px;
      }
    }

    @include media-breakpoint-down(sm) {
    }
  }

  @include media-breakpoint-down(sm) {
    .view-toggles {
      flex-direction: column;

      .toggle-buttons-group {
        .toggle-button {
          overflow: scroll;
        }
      }
    }
  }
}
