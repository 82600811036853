@use '../abstracts/' as *;
@use '../../variables' as vars;

.header {
  width: 100%;
  :global(.container) {
    @include media-breakpoint-up(lg) {
      max-width: 1140px !important;
    }
  }
  .topBar {
    min-height: 35px;
    background: #2b2b2b;
    text-transform: uppercase;
    width: 100%;
    img {
      height: 20px;
      width: auto;
      position: relative;
      top: 6px;
      display: inline-block;
      vertical-align: initial;
    }
    a {
      display: inline-block;
      font-weight: bold;
      line-height: 36px;
      letter-spacing: 0.25px;
    }
    .contactButtons {
      margin-left: 15px;
      font-size: 16px;
      color: #f2f2f2;
    }
    .actionButtons {
      font-size: 16px;
      color: #e0e0e0;
      &.login {
        color: vars.$neon-yellow;
      }
    }
    .line {
      border-right: 1px solid vars.$neon-yellow;
      padding: 5px 0px;
      position: relative;
      top: 10px;
    }
  }
  .bottomBar {
    z-index: 1000;
    min-height: 75px;
    background: #ffffff;
    padding: 10px 0;
    box-shadow:
      0px 4px 8px rgba(38, 50, 56, 0.08),
      0px 2px 4px rgba(38, 50, 56, 0.16),
      0px -4px 4px rgba(0, 0, 0, 0.03);
    transition: min-height 0.3s ease-in-out;
    &.squeeze {
      position: fixed;
      top: 0;
      width: 100%;
      padding: 5px 0;
      min-height: 54px;
      transition: min-height 0.3s ease-out-in;
    }
    .navRow {
      text-align: center;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 1.2px;
      .navCol,
      a {
        color: $rivall-navy;
        margin: 3px 10px;
      }
      .menu {
        padding-top: 6px;
        float: right;
        &:hover {
          cursor: pointer;
        }
      }
    }
    .dropdownButton {
      &::after {
        background-image: url('https://rivall-public.s3-us-west-1.amazonaws.com/home-page/down-caret.png');
        background-image: -webkit-image-set(
          url('https://rivall-public.s3-us-west-1.amazonaws.com/home-page/down-caret.avif') 1x,
          url('https://rivall-public.s3-us-west-1.amazonaws.com/home-page/down-caret.webp') 1x,
          url('https://rivall-public.s3-us-west-1.amazonaws.com/home-page/down-caret.png') 1x
        );
        background-image: image-set(
          url('https://rivall-public.s3-us-west-1.amazonaws.com/home-page/down-caret.avif')
            type('image/avif'),
          url('https://rivall-public.s3-us-west-1.amazonaws.com/home-page/down-caret.webp')
            type('image/webp'),
          url('https://rivall-public.s3-us-west-1.amazonaws.com/home-page/down-caret.png')
            type('image/png')
        );
        background-size: 15px;
        display: inline-block;
        height: 13px;
        width: 16px;
        content: '';
        margin-left: 7px;
      }
      .dropdown {
        transition:
          visibility 0.5s,
          opacity 0.7s;
        visibility: hidden;
        opacity: 0;
        width: intrinsic;
        width: -moz-max-content;
        width: -webkit-max-content;
        width: max-content;
        max-width: 93vw;
        position: absolute;
        top: 60px;
        left: 0;
        background: #ffffff;
        padding: 20px;
        border-radius: 8px;
        box-shadow:
          0px 4px 8px rgba(38, 50, 56, 0.08),
          0px 2px 4px rgba(38, 50, 56, 0.16),
          0px -4px 4px rgba(0, 0, 0, 0.03);
        z-index: 99;
        max-height: 90vh;
        &:hover,
        &:active {
          visibility: visible;
          opacity: 1;
          z-index: 999999;
        }

        .vp-link {
          &:hover {
            color: $brick-red !important;
          }
        }

        .innerList {
          display: block;
          li {
            line-height: 25px;
            list-style-type: none;
            font-weight: 500;
            font-size: 16px;
            letter-spacing: 0.0025em;
            width: max-content;
            a {
              color: #2b2b2b;
              &:hover {
                color: #0171b9;
                border-left: 2px solid #0171b9;
                padding-left: 4px;
              }
            }
          }
        }
        h4 {
          text-align: center;
          font-weight: bold;
          font-size: 16px;
          letter-spacing: 0.0025em;
          color: #2b2b2b;
          padding: 0px 10px 5px;
          margin: 6px;
          border-bottom: 2px solid #0171b9;
          text-transform: uppercase;
        }
        input {
          border-radius: 3px;
        }
        .email {
          width: 300px;
        }
      }
      @media (min-width: 1060px) and (max-width: 1600px) {
        .pull-left {
          left: -15vw;
        }
      }
      @media (min-width: 1000px) and (max-width: 1059.99px) {
        .pull-left {
          left: -35vw;
        }
      }
      @media (min-width: 767.99px) and (max-width: 999.99px) {
        .pull-left {
          left: -55vw;
        }
      }
      &:hover,
      &:active {
        cursor: pointer;
        .dropdown {
          visibility: visible;
          opacity: 1;
          z-index: 99999999;
        }
      }
    }

    .chooseCity {
      width: 250px;
      div {
        z-index: 999;
        font-size: 16px !important;
        font-weight: bold !important;
        line-height: 19px;
        letter-spacing: 1.2px;
      }
    }

    @include media-breakpoint-down(sm) {
      .chooseCity {
        width: 100%;
      }
      .small-hide-col {
        display: none;
      }
      .logo-col {
        text-align: left;
      }
      .brandLogosBanner {
        img {
          height: 29px;
          padding: 0.2rem;
        }
      }
    }
  }
  .discover-bar-link {
    font-size: 15px;
    display: flex;
    font-weight: bold;
    align-items: center;
    line-height: 36px;
    letter-spacing: 0.25px;
    color: vars.$rivall-navy;
  }
  .discover-bar-link-bold {
    color: vars.$rivall-black;
    text-shadow: 0.5px 0 vars.$rivall-black;
  }
  .voloAppContainer {
    border-left: 1px solid lightgray;
    height: 100%;
    .voloAppImg {
      height: 250px;
      width: auto;
    }
    h4 {
      border-bottom: none !important;
      line-height: 20px !important;
    }
  }
}
