@import '~/src/styles/_variables.scss';
@import '~/src/styles/_mixins.scss';

footer {
  width: 100%;
  background-color: $dark-neutral;
  padding: 5px 0;
  .right {
    @include flex-item(0, 1);
    text-align: right;
    ul {
      margin: 0;
    }
  }
  li {
    display: inline-block;
    margin: 0 10px;
    a {
      @include link(white);
      font-weight: 300;
      font-size: 12px;
    }
  }
  .copyright {
    button {
      color: $grey-500 !important;
      font-size: 12px;
      font-weight: 300;
      padding: 1px 3px;
      &:active,
      &:focus,
      &:hover {
        color: $primary !important;
      }
    }
  }
  @media screen and (max-width: $mobile-width) {
    li {
      line-height: 1;
      margin: 4px;
      a,
      &.copyright {
        font-size: 12px;
      }
    }
    .social {
      > a {
        margin-top: 0;
      }
      p {
        display: block;
        font-size: 12px;
        margin-top: 5px;
        a {
          font-size: 12px;
        }
      }
    }
  }
}
