@use '../styles/volo/abstracts/registerVariables' as rv;
@import '../styles/_variables.scss';
@import '../styles/_mixins.scss';

.App {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background-color: white;
  width: 100%;
  max-width: 100%;
  > span {
    width: 100%;
  }
  &.create,
  &.invite,
  &.signup,
  &.createDropin {
    background-color: $bg-light;
  }
}

.Page {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding-top: 40px; // header buffer
  background-color: white;
  overflow: hidden;
  @media (max-width: 768px) {
    overflow-x: hidden;
  }
  &.overflow {
    overflow: visible;
  }
  &.discover {
    flex: 1;
    padding-top: 0;
  }
  &.register {
    height: fit-content;
  }
  &.rental {
    background-color: $grey-100 !important;
  }
  @media (max-width: 767.98px) {
    &.error {
      padding-top: 0;
    }
  }
}

.rfp.dashboard,
.rfp.member,
.l,
.g,
.rfp.register,
.rfp.membership {
  :global(.container) {
    @include media-breakpoint-up(lg) {
      max-width: $max-container-width-player;
    }
  }
}

.rfp .Page,
.rfo .Page,
.anon .Page,
.staff .Page {
  background-color: white;

  &.create,
  &.settings,
  &.create2,
  &.leagues,
  &.signup,
  &.createDropin,
  &.deepLinks,
  &.dashboard {
    flex: 1;
    background-color: $bg-light;
  }
}

.create2 {
  background-color: $bg-light;
}

.signup {
  background-color: $bg-light;
}

.App.rfo {
  .Page.league {
    width: 100%;
    max-width: 100%;
    background-color: $bg-light;
  }
  .Page.dashboard {
    background-color: $bg-light;
    min-width: 790px;
  }
}

.no-header {
  padding-top: 0;
}

$logo-spacer: 100px;
$logo-height: 40px;

.homepage {
  padding: 0;
  @include flex(stretch, stretch, stretch);
  @include flex-item(1, 0, auto, stretch);
  max-width: 100%;

  .homepage-content {
    @include flex-item(1, 0, auto, stretch);
    @include flex(stretch, stretch, stretch);
    max-width: 100%;
    background-color: blue;
    position: relative;
  }

  section {
    @include flex(center, center, stretch, wrap);
    .section-content {
      padding: 20px 50px;
      margin-bottom: $logo-spacer + $logo-height;
    }
  }

  .login-section {
    @include flex-item(0, 1, 40%, stretch);
    background-color: white;
    text-align: center;
    .spacer {
      height: $logo-spacer + $logo-height;
    }

    h3 {
      font-size: 26px;
      font-weight: 300;
    }
  }

  .signup-section {
    @include flex-item(0, 1, 60%, stretch);
    background-color: $dark-neutral;
    background-image: url('../images/photos/night-soccer.jpg');
    background-size: cover;
    text-align: left;
    .section-content {
      max-width: 100%;
    }

    .title {
      img {
        height: $logo-height;
        max-width: 100%;
      }
      margin-bottom: $logo-spacer;
    }

    h3 {
      font-size: 26px;
      font-weight: 300;
      color: white;
    }

    h5 {
      font-weight: 300;
      font-size: 16px;
      color: white;
      margin: 10px 0 15px;
    }

    .player-cta a {
      margin-top: 10px;
      font-size: 18px;
      min-width: 260px;
    }
  }

  .info-links {
    position: absolute;
    bottom: 10px;
    left: 40px;
    ul {
      list-style-type: none;
      li {
        display: inline-block;
        color: $lightish-neutral;
        font-size: 13px;
        a {
          color: $lightish-neutral;
        }
        &:before {
          content: '·';
          margin: 0 5px;
        }
        &:first-child:before {
          content: '';
          margin: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .homepage {
    @include flex-column(stretch, stretch, stretch);
    .homepage-content {
      @include flex-column(stretch, stretch);
    }

    section {
      @include flex-column(stretch, stretch, center);
      margin-top: 0;
      .section-content {
        padding: 20px 40px;
        max-width: 100%;
        margin: 0;
      }
    }

    .login-section {
      @include flex-item(0, 1, auto, stretch);
      margin-top: 0px;
      .spacer {
        height: 0px;
      }
      .section-content {
        padding: 20px 0;
        margin: 20px auto 0;
      }

      h3 {
        font-size: 20px;
        font-weight: 300;
      }
    }

    .signup-section {
      @include flex-item(1, 0, auto, stretch);
      text-align: center;
      background-position: center bottom 20%;
      min-height: 340px;
      .section-content {
        max-width: 100%;
        margin: 30px auto;
      }

      .title {
        margin: 20px auto 30px;
      }

      h5 {
        margin: 10px auto;

        a {
          color: $main;
        }
      }
    }

    .info-links {
      position: static;
      margin: 0 auto 10px;
    }
  }
}

@media screen and (max-width: 550px) {
  .homepage {
    .signup-section {
      background-position: bottom;
      .title {
        margin: 50px auto 20px;
      }
    }
  }
}
