@use '~/src/styles/volo/abstracts/' as *;
@use '~/src/styles/volo/components/' as *;

@mixin button {
  -webkit-appearance: none !important;
  @include transition(0.3s);
  border-radius: 30px;
  padding: 9px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 500;
  color: white;
  text-align: center;
  outline: 0;
  g {
    fill: white;
  }
  &.disabled,
  &:disabled {
    cursor: not-allowed;
    background-color: $btn-disabled-bg !important;
    border-color: $btn-disabled-bg !important;
    box-shadow: none !important;
    color: $btn-disabled-color !important;
    g {
      fill: $btn-disabled-color !important;
    }
  }
}

@mixin button-color($color: $grey-800, $text-color: #fff) {
  background-color: $color;
  border: 1px solid $color;
  @include box-shadow(0, 0, 0, 0, $color);
  &:hover {
    background-color: tint($color, 10%);
    border-color: tint($color, 10%);
    @include box-shadow(0, 0, 0, 2px, $color);
    color: $text-color;
    g {
      fill: $text-color;
    }
  }
  &:focus {
    opacity: 1;
  }
  &:active,
  &:active:focus {
    background-color: shade($color, 10%);
    border-color: shade($color, 10%);
    color: $text-color;
    g {
      fill: $text-color;
    }
  }
}

@mixin button-link($color: $text-color, $bg: $white-neutral) {
  color: $color;
  g {
    fill: $color;
  }
  &:hover,
  &:focus,
  &:active {
    box-shadow: none;
    background-color: $bg;
    border-color: $bg;
    color: tint($color, 10%);
    g {
      fill: tint($color, 10%);
    }
  }
  &.disabled,
  &:disabled {
    background-color: transparent !important;
    border-color: transparent !important;
  }
}

@keyframes loading {
  0%,
  100% {
    opacity: 0.7;
  }
  50% {
    opacity: 1;
  }
}

.button {
  @include button;
  @include button-color;
  display: inline-block;
  &.loading {
    animation: loading 1s infinite;
    cursor: wait;
  }
  &.link {
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
    &:active,
    &:hover,
    &:focus {
      box-shadow: none;
    }
    @include button-link;
  }
  &.square {
    border-radius: 3px;
  }
  &.block {
    display: block;
  }
}

a.button {
  -webkit-appearance: none !important;
  text-decoration: none !important;
}

.wide {
  padding-left: 40px;
  padding-right: 40px;
}

.block {
  width: 100%;
}

.large {
  font-size: 16px;
  border-radius: 100px;
}

.small {
  padding: 4px 10px;
  font-weight: 500;
}
// white button options

@mixin button-ghost($text: $primary, $borderColor: $primary) {
  background-color: #fff;
  border: 1px solid $borderColor;
  color: $text;
  g {
    fill: $text;
  }
  @include box-shadow(0, 0, 0, 0, $borderColor);
  &:hover,
  &:focus:not(.inactive) {
    border-color: $borderColor;
    color: $text;
    @include box-shadow(0, 0, 0, 2px, $borderColor);
    g {
      fill: $text;
    }
  }
  // &:active, &:active:focus {
  //   background-color: $white-neutral;
  //   border-color: $borderColor;
  //   color: $text;
  //   g { fill: $text; }
  // }
}

.fullWidth {
  flex: 1 1 0px;
  justify-content: center;
}
.selected {
  background-color: $light-teal;
}

.success {
  @include button-color($success);
  &.link {
    @include button-link($success);
  }
}

.primary {
  @include button-color($primary);
  &.link {
    @include button-link($primary);
  }
  &.ghost {
    @include button-ghost($primary, $primary);
  }
}

.navy {
  @include button-color($rivall-navy);
  &.link {
    @include button-link($rivall-navy);
  }
  &.ghost {
    @include button-ghost($rivall-navy, $rivall-navy);
  }
}

.neon {
  @include button-color($neon-teal);
  &.link {
    @include button-link($neon-teal);
  }
  &.ghost {
    @include button-ghost($neon-teal, $neon-teal);
  }
}

.skyblue {
  @include button-color($sky-blue);
  &.link {
    @include button-link($sky-blue);
  }
  &.ghost {
    @include button-ghost($sky-blue, $sky-blue);
  }
}

.action {
  @include button-color($action);
  &.link {
    @include button-link($action);
  }
  &.ghost {
    @include button-ghost($action, $action);
  }
}

.danger {
  @include button-color($danger);
  &.link {
    @include button-link($danger);
  }
}

.default {
  @include button-ghost($text-color, $light-neutral);
  background-color: white;
  &.ghost {
    @include button-ghost($grey-800, $grey-800);
  }
}

.white {
  @include button-ghost($text-color, white);
  background-color: white;
  &.link {
    @include button-link($text-muted);
  }
}

.dark {
  @include button-color($text-muted, $text-color);
  color: $text-color;
}

.grey {
  @include button-color($grey-input-color, $text-color);
  color: $text-color;
}

.light {
  font-weight: 400;
}

.bold {
  font-weight: 600;
}
