.html-editor {
  p {
    font-size: 14px;
  }
  textarea {
    margin-bottom: 20px;
    padding: 14px;
    font-size: 14px;
    height: 300px;
  }
  label {
    text-transform: uppercase;
    color: #a9a9a9;
    margin: 15px 0 3px 4px;
    display: block;
    font-weight: 500;
    text-align: left;
  }
}
