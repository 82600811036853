@import '~/src/styles/_variables.scss';
@import '~/src/styles/_mixins.scss';

.collapsedMenu {
  .addButton {
    border-radius: 50%;
    font-size: 26px;
    height: 35px;
    width: 35px;
    padding: 2px;
    position: absolute;
    bottom: 5px;
    left: 5px;
    &.clicked {
      border: 1px solid $grey-300;
    }
  }
  .addMenu {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    bottom: 50px;
    left: 5px;
    transition: opacity 0.2s;
    .addMenuItem {
      background-color: $rivall-blue;
      border: 1px solid $grey-300;
      color: $grey-100;
      font-weight: 800;
      padding: 15px;
      text-transform: uppercase;
      &:first-child {
        border-radius: 8px 8px 0 0;
      }
      &:last-child {
        border-radius: 0 0 8px 8px;
      }
      &:only-child {
        border-radius: 8px;
      }
      &:hover {
        background-color: $grey-200;
        color: $grey-800;
        cursor: pointer;
      }
    }
    &.clicked {
      opacity: 1;
      pointer-events: auto;
    }
  }
}
