// current colors
$rivall-blue: #1e62b4;
$rivall-blue-300: #4a90e2;
$rivall-navy: #0a3355;
$rivall-dark: #2b4255;
$brick-red: #b4371e;
$dusty-teal: #509371;
$sun-yellow: #fcce2e;
$tangerine: #f5a41d;
$facebook: #3b5998;
$stripe: #5b66de;
$neon-yellow: #e9ff70;
$neon-teal: #4ce0d2;
$light-teal: #c0f4ef;
$sky-blue: #1a85de;
$white: #fff;

$dropin: #ce2195;

// New neutrals
$grey-800: #4c4c4c;
$grey-600: #747474;
$grey-500: #979797;
$grey-400: #aaaaaa;
$grey-300: #d7d7d7;
$grey-200: #ebeeee;
$grey-100: #f7f7f7;

// Translated legacy variables (deprecate)
$darkish-neutral: $grey-800;
$med-neutral: $grey-500;
$lightish-neutral: $grey-400;
$white-neutral: $grey-100;

// Legacy colors
$tangerine: rgb(245, 164, 29);
// $rivall-blue: rgb(75, 126, 226); // hex: 4b7ee2
$rivall-dark-blue: rgb(51, 102, 204);
$rivall-yellow: rgb(250, 92, 0);
$rivall-black: rgb(51, 51, 51);
$rivall-gray: rgb(112, 112, 112);
$rivall-map-blue: rgb(60, 100, 180);
$orange: $tangerine;

$legacy-white-neutral: #f3f6f6;
$neutral: #999;
$lighter-neutral: #ebeeee;
$light-neutral: #dadcdc;
$legacy-lightish-neutral: #b4b6b6;
$legacy-med-neutral: #757676;
$legacy-darkish-neutral: #363737;
$dark-neutral: #262626;
$black-neutral: #191919;

// OG colors
$main: #9ce2db;

$pending: #b4b6b6;
$needs_approval: #e6701c;
$registration_open: #724bde;
$ready: #24567c;
$active: #17d817;

$light-trans-gray: rgba(230, 230, 230, 0.2);
$divider-gray: #b3b9c5;
