@import '~/src/styles/variables';
@import '~/src/styles/_mixins.scss';

.counter {
  border-radius: 32px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #163352;
  color: white;
  font-weight: 600;
}

.smallCounter {
  border-radius: 24px;
  width: 24px;
  height: 24px;
}

.crest {
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  background-color: white;
  position: relative;
  left: -1.5rem;
  border-radius: 50%;
  padding: 0;
  z-index: 99999;
}

.dark {
  color: #163352;
  background-color: white;
}

.filterCheck {
  width: 0;
  height: 0;
  border-radius: 4px;
  margin: 0 8px 0 0;
  padding: 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition:
    background-color 0.3s,
    height 0.3s,
    width 0.3s;
  box-sizing: border-box;
}

.showCheck {
  background-color: #e9ff70;
  width: 20px;
  height: 20px;
}

.filterCheck:hover {
  box-shadow: inset 0 0 4px $grey-800;
}

.errorMsg {
  color: darkred;
  text-align: center;
  margin-top: 2px;
}
