@use '../../abstracts/' as *;

.discoverHeader {
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid $grey-300;
  display: flex;
  height: $discover-header-height;
  padding-left: $discover-margin;
  padding-right: $discover-margin;

  .headerLogo {
    order: 1;
    display: flex;
    justify-content: flex-start;
    min-width: 30px;

    &:hover {
      cursor: pointer;
    }
  }

  .headerButtons {
    order: 2;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    position: relative;

    .cityButton {
      display: flex;
      border-radius: $default-shadow;
      box-shadow: $default-shadow;
      border-radius: 4px;

      > div {
        margin-top: 8px;
        margin-bottom: 8px;
      }
    }

    .courtRentalButton {
      position: absolute;
      min-width: 190px;
      font-weight: 700;
      height: 100%;
      left: 20px;
    }

    .playNowButton {
      width: fit-content;
      font-weight: 800;
      margin-left: 0.5rem;

      > img {
        display: none;
      }

      &::after {
        content: 'PLAY NOW';
        letter-spacing: -0.3px;
        font-size: 1em;
      }
    }
  }

  .headerNavigation {
    order: 3;
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin-right: 12px;
  }

  @include media-breakpoint-down(md) {
    padding: 16px;
    height: $discover-header-height;
    flex-wrap: nowrap;
    justify-content: space-between;

    .headerLogo {
      order: 1;
      width: fit-content;
    }

    .headerButtons {
      .courtRentalButton {
        position: relative;
        font-size: 10px;
        min-width: min-content;
        padding: 0 5px;
        margin-right: 2rem;
      }

      .playNowButton {
        > img {
          display: block;
          min-height: 0.5rem;
          min-width: 0.5rem;
          fill: green;
        }

        &::after {
          display: none;
        }
      }
    }

    .headerNavigation {
      order: 2;
      width: fit-content;
    }
  }
}

.profile-img {
  border-radius: 18px;
  height: 36px;
  min-width: 20px;
  cursor: pointer;
}
