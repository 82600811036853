@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

// =====================
// = Style customizing =
// =====================

// Neutrals. Left changable so brands can follow color guidelines.
$color-0: white;
$color-1: $dark-neutral;
$color-2: $med-neutral;
$color-4: $dark-neutral;
$disabled-color: $light-neutral;

// Default width of all items.
$fancy-width: 260px;

// Sets the top of a revealed magic label.
$magic-label-top: -7px;

$font-size: 14px;
$mobile-font-size: 12px;

.fancy-field,
.fancy-button {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
  p {
    margin: 0;
  }
  &.scoot-up {
    margin-top: -5px;
  }
  &.center {
    margin: 0 auto;
  }
  &.disabled {
    @include placeholder {
      color: $lightish-neutral;
    }
    .magic-label,
    input {
      color: grey;
    }
    input {
      background-color: $white-neutral;
    }
  }
}

// ====================
// ======= Field ======
// ====================

.fancy-field {
  // line-height: 1.3;
  max-width: 100%;
  width: $fancy-width;

  &.textarea {
    width: 100%;
  }

  .wrap {
    position: relative;
    width: 100%;
  }

  @include placeholder {
    @include transition(0.3s);
    opacity: 1;
    // color: $med-neutral;
    color: $lightish-neutral;
  }

  input,
  textarea {
    @include transition(0.2s);
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    font-family: inherit;
    display: block;
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    background: $color-0;
    border: 1px solid $light-neutral;
    border-radius: 2px;
    color: $dark-neutral;
    padding: 8px 10px;
    font-weight: 300;

    @include placeholder-on-focus {
      opacity: 0;
    }

    &:focus,
    &:active {
      outline: 0;
      border: 1px solid $primary;
    }
    &:disabled {
      color: $color-1;
    }

    font-size: $font-size;
    @media screen and (max-width: 500px) {
      font-size: $mobile-font-size;
    }
  }

  textarea {
    resize: none;
    font-size: 14px;
    line-height: 1.2;
    height: 100px;
    width: 100%;

    &.large {
      height: 200px;
    }

    @media screen and (max-width: 500px) {
      font-size: $mobile-font-size;
    }
  }

  input[type='date'],
  input[type='time'] {
    padding: 6px 8px;
    color: $lightish-neutral;
  }
  &.populated input[type='date'],
  &.populated input[type='time'] {
    color: $dark-neutral;
  }

  p.magic-label {
    @include transition(0.3s);
    pointer-events: none;
    position: absolute;
    opacity: 0;
    border-radius: 10px;
    padding: 0 5px;

    top: 0px;
    left: 8px;
    color: $dark-neutral;
    font-size: 10px !important;
    font-weight: 600;

    @include linear-gradient(bottom, $color-0, ($magic-label-top - 2) * -1, transparent, 1px);
    background-image: linear-gradient(
      to top,
      $color-0 ($magic-label-top - 2) * -1,
      transparent 1px
    );

    &.populated {
      opacity: 1;
      top: $magic-label-top;
    }
    &.focused {
      opacity: 1;
      color: $primary;
      top: $magic-label-top;
    }
  }

  &.no-float .magic-label.populated,
  &.no-float .magic-label.focused {
    opacity: 0;
  }

  .unit {
    @include transition(0.3s);
    position: absolute;
    pointer-events: none;
    margin: 0;
    opacity: 0;

    color: $color-2;
    font-size: 14px;
    left: 10px;
    top: 14px;
    &.shown {
      opacity: 1;
    }
  }

  &.error {
    input {
      border: 1px solid $error;
    }
    .magic-label,
    .magic-label.populated {
      color: $error;
    }
  }

  .fancy-price {
    padding: 0 5px;
    span {
      position: absolute;
      left: 15px;
      top: 9px;
      font-weight: 400;
    }

    input {
      padding-left: 20px;
    }
  }

  &.auto-center {
    margin: 0 auto;
  }
}
.persist .fancy-field {
  @include placeholder {
    color: $color-4;
  }
}

.leagueUrl.fancy-field {
  &.populated,
  &.focused {
    input {
      padding-left: 108px !important;
    }
  }
  .unit {
    top: 13px;
  }
}

// ==== Special Classes ====

.fancy-field {
  // fix for autofill shit
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
    -webkit-text-fill-color: $dark-neutral !important;
  }
  &.ghost {
    input,
    input:hover,
    input:focus {
      background-color: rgba(255, 255, 255, 0.1);
      border: 1px solid white;
      color: white;
      padding-left: 10px;
      padding: 8px 10px;
    }
    @include placeholder {
      color: rgba(255, 255, 255, 0.7);
    }
    .magic-label.populated,
    .magic-label.focused,
    .magic-label {
      padding: 0 5px;
    }

    input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0px 1000px $primary inset;
      -webkit-text-fill-color: white !important;
      border: 1px solid $dark-neutral;
    }
  }

  &.small {
    input {
      font-size: 14px;
      padding: 1px 5px 0;
    }
    .magic-label {
      font-size: 10px;
      padding: 0px;
      left: 5px;
    }
  }
}

// =========================
//    Messages and carats
// =========================

.message-container {
  @include transition(0.3s);
  display: inline-block;
  position: absolute;
  z-index: 4;
  opacity: 0;
  margin-top: 5px;
  pointer-events: none;

  &.shown {
    opacity: 1;
  }

  .message {
    font-size: 12px;
    font-weight: 300;
    border-radius: 2px;

    width: auto;
    height: auto;
    padding: 5px;
  }

  .carat {
    left: 14px;
    top: 0;
  }
}

.error-message {
  .message {
    color: $color-0;
    background-color: $error;
    border: 1px solid $error;
  }
  .carat {
    @include tiny-carat($error);
  }
}

.warning-message {
  .message {
    color: white;
    background-color: $med-neutral;
    border: 1px solid $med-neutral;
  }
  .carat {
    @include tiny-carat($med-neutral);
  }
}

.disabled-text,
.focus-text {
  opacity: 0;
  .message {
    color: $color-4;
    border: 1px solid $color-1;
    background: $color-0;
  }
  .carat {
    display: block;
    @include tiny-carat($color-0, $color-1);
    top: 1px;
  }
}
