@import '../../styles/_variables.scss';

.ie-warning {
  position: absolute;
  background-color: white;
  @include ev-2;
  width: 320px;
  padding: 20px;
  top: 10px;
  left: 10px;
  font-size: 12px;
  border-radius: 2px;
  > div {
    margin-top: 10px;
  }
}
