@use 'sass:math';

// Flexbox
// Flex if static width: 0 1 100px
// Flex if should stretch: 1 0 auto
@mixin flex-column($align: stretch, $align-items: stretch, $justify: flex-start, $wrap: nowrap) {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: $wrap;
  -ms-flex-wrap: $wrap;
  flex-wrap: $wrap;
  -webkit-justify-content: $justify;
  -ms-flex-pack: $justify;
  justify-content: $justify;
  -webkit-align-content: $align;
  -ms-flex-line-pack: $align;
  align-content: $align;
  -webkit-align-items: $align-items;
  -ms-flex-align: $align-items;
  align-items: $align-items;
}
@mixin flex($align: stretch, $align-items: center, $justify: flex-start, $wrap: nowrap) {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: $wrap;
  -ms-flex-wrap: $wrap;
  flex-wrap: $wrap;
  -webkit-justify-content: $justify;
  -ms-flex-pack: $justify;
  justify-content: $justify;
  -webkit-align-content: $align;
  -ms-flex-line-pack: $align;
  align-content: $align;
  -webkit-align-items: $align-items;
  -ms-flex-align: $align-items;
  align-items: $align-items;
  margin: 0; // ie fix
}
@mixin flex-item($grow: 1, $shrink: 0, $width: auto, $align: auto) {
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: $grow $shrink $width;
  -ms-flex: $grow $shrink $width;
  flex: $grow $shrink $width;
  -webkit-align-self: $align;
  -ms-flex-item-align: $align;
  align-self: $align;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
  }
}

// Common themes
@mixin caps {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
}
@mixin emph {
  font-weight: 600;
  font-style: italic;
  font-size: 12px;
}
@mixin disable($message: 'You found a disabled button!') {
  position: relative;
  > *,
  button,
  a {
    pointer-events: none !important;
    opacity: 0.5;
  }
  &:after {
    content: $message;
    position: absolute;
    min-width: 70px;
    border: 1px solid $dark-neutral;
    color: $dark-neutral;
    background-color: $warning;
    top: 50%;
    left: 50%; /* position the left edge of the element at the middle of the parent */
    transform: translate(-50%, -50%);
    font-size: 10px;
    padding: 0 3px;
    font-size: 12px;
    display: none;
  }
  &:hover:after {
    display: block;
  }
}
@mixin linethru-header {
  text-align: left;
  position: relative;
  border-top: 1px solid $light-neutral;
  padding: 20px 20px;
  h2 {
    font-size: 22px;
    font-weight: 300;
    position: absolute;
    top: -14px;
    left: 16px;
    background-color: white;
    display: inline-block;
    padding: 0 4px;
  }
}

// Webkit helpers
@mixin transition($duration) {
  -webkit-transition: all $duration ease;
  -moz-transition: all $duration ease;
  -ms-transition: all $duration ease;
  -o-transition: all $duration ease;
  transition: all $duration ease;
}
@mixin box-shadow($ho, $vo, $blur, $spread, $color: rgba(230, 230, 230, 0.9)) {
  -webkit-box-shadow: $ho $vo $blur $spread $color;
  -moz-box-shadow: $ho $vo $blur $spread $color;
  box-shadow: $ho $vo $blur $spread $color;
}
@mixin box-shadow-basic {
  @include box-shadow(0, 0, 32px, 3px, rgba(0, 0, 0, 0.2));
}
@mixin placeholder {
  ::-webkit-input-placeholder {
    @content;
  }
  :-moz-placeholder {
    @content;
  }
  ::-moz-placeholder {
    @content;
  }
  :-ms-input-placeholder {
    @content;
  }
}
@mixin placeholder-on-focus {
  &:focus::-webkit-input-placeholder {
    @content;
  }
  &:focus:-moz-placeholder {
    @content;
  }
  &:focus::-moz-placeholder {
    @content;
  }
  &:focus:-ms-input-placeholder {
    @content;
  }
}

@mixin rotate($degrees) {
  -webkit-transform: rotate($degrees);
  -moz-transform: rotate($degrees);
  -ms-transform: rotate($degrees);
  -o-transform: rotate($degrees);
  transform: rotate($degrees);
  transform-origin: center center;
}
@mixin linear-gradient($direction, $color, $pcnt1, $color2, $pcnt2) {
  background-image: -webkit-linear-gradient($direction, $color $pcnt1, $color2 $pcnt2);
  background-image: -moz-linear-gradient($direction, $color $pcnt1, $color2 $pcnt2);
  background-image: -o-linear-gradient($direction, $color $pcnt1, $color2 $pcnt2);
  // add standard syntax afterwards, eg: linear-gradient(to top, color pcnt, color pcnt);
}
@mixin animation($animation) {
  -webkit-animation: $animation;
  -o-animation: $animation;
  animation: $animation;
}

// Components
@mixin container($padding: 20px) {
  max-width: $max-container-width + $padding + $padding;
  padding: $padding;
  margin: 0px auto;
}
@mixin button($color) {
  @include transition(0.2s);
  display: inline-block;
  border: 1px solid $color;
  background: $color;
  padding: 2px 4px;
  border-radius: 30px;
  color: white;
  cursor: pointer;
  font-weight: 400;
  @include box-shadow(0, 0, 0, 0, $color);
  &:hover {
    @include box-shadow(0, 0, 0, 2px, $color);
  }
  &:focus {
    opacity: 0.8;
  }
  &.disabled {
    pointer-events: none;
    background-color: transparent;
    color: $color;
    opacity: 0.8;
  }
  &.large {
    padding: 6px 12px;
  }
}
@mixin ghost-button($color) {
  @include button($color);
  background-color: transparent;
  color: $color;
}
@mixin link($color) {
  @include transition(0.2s);
  display: inline-block;
  color: $color !important;
  cursor: pointer;
  &:hover {
    border-color: $color;
  }
  // TODO: Footer links that aren't technically links don't have proper underline on hover
}
@mixin inline-wrap {
  display: inline-block;
  vertical-align: middle;
  padding: 20px;
  &.half {
    width: 50%;
  }
}
@mixin popup($popup-width) {
  position: absolute;
  top: 10%;
  left: 50%;
  margin-left: math.div($popup-width, -2);
  width: $popup-width;
  background: white;
  padding: 30px;
  @include box-shadow-basic;
  @media screen and (max-width: $popup-width) {
    width: 100%;
    text-align: center;
    margin-left: 0;
    left: 0;
  }
}
@mixin close-x($size: 10px) {
  width: $size + 10;
  height: $size + 10;
  background-repeat: no-repeat;
  background-size: $size;
  background-position: center;
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 0px;
  &:hover {
    border: 0;
  }
}
@mixin small-carat($color, $border: transparent) {
  position: absolute;
  background: $color;
  &:after,
  &:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: $color;
    border-width: 10px;
    margin-left: -10px;
  }
  &:before {
    border-color: transparent;
    border-bottom-color: $border;
    border-width: 11px;
    margin-left: -11px;
  }
}
@mixin tiny-carat($color, $border: transparent) {
  position: absolute;
  background: $color;
  &:after,
  &:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: $color;
    border-width: 5px;
    margin-left: -5px;
  }
  &:before {
    border-color: transparent;
    border-bottom-color: $border;
    border-width: 6px;
    margin-left: -6px;
  }
}
@mixin overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
