@use '../../abstracts/' as *;
@use '../../abstracts/registerVariables' as rv;

.register-field {
  $transparent-neon-teal: rgba(76, 224, 210, 0.05);

  background-color: $transparent-neon-teal;
  color: $volo-primary;
  padding: 1.75rem 1rem;
  width: 100%;
  margin-bottom: 0.9rem;
  border-radius: 4px;

  h2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    margin-bottom: 1rem;

    .subtitle {
      font-size: 0.8rem;
      font-weight: 100;
    }
  }

  h3 {
    margin-bottom: 1rem;
    font-size: 0.75rem;
  }

  .register-checkbox {
    display: flex;
    align-items: center;

    input[type='checkbox'] {
      margin-right: 1rem;

      &::after {
        background-color: white;
      }
    }

    span {
      letter-spacing: 1px;
    }
  }

  .dark-text {
    color: black;
  }
  .light-text {
    color: white;
  }

  .register-text-field {
    background-color: white;
    border: 1px solid $neutral-04;
    border-radius: 4px;
    padding: 0.75rem;
    letter-spacing: 1px;

    .line {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
      align-items: center;

      small {
        letter-spacing: 0;
      }
    }

    .divider {
      border-bottom: 1px solid $neutral-03;
      margin-bottom: 1rem;
    }
  }

  .small {
    font-size: 0.7rem;
  }

  .confirmation-links {
    font-size: 1rem;

    a {
      display: block;
      margin: 0.5rem 0;
    }
  }

  .password-footnote {
    margin: 0 0 0.8rem;
  }

  @media (min-width: rv.$breakpoint-md) {
    margin-top: 0.9rem;
    width: calc(100% - 10px);

    h3 {
      margin-bottom: 1rem;
      font-size: 1.1rem;
    }
  }
}
