@import '~/src/styles/_variables.scss';
@import '~/src/styles/_mixins.scss';

.search-users-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .user-found {
    padding: 6px 10px;
    margin: 0;
  }
  .label,
  label {
    font-size: 14px;
    font-weight: bold;
  }
  .search-input {
    width: 400px;
    margin: 10px auto;
  }
}
