@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.icon {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  .circle,
  .profpic {
    border-radius: 25%;
    // border: 1px solid $dark-neutral;
    background-size: cover;
    background-position: center;
    background-color: $primary;
  }

  .circle {
    height: 40px;
    width: 40px;
  }

  .profpic {
    height: 4rem;
    width: 4rem;
    background-color: white;
  }
  h3 {
    color: white;
    // color: $dark-neutral;
    font-size: 18px;
    font-weight: 300;
    margin: 0 auto;
    padding: 0;
    line-height: 40px;
  }

  &.small {
    .circle {
      height: 30px;
      width: 30px;
    }
    h3 {
      font-size: 15px;
      line-height: 30px;
    }
  }

  &.large {
    .circle {
      height: 50px;
      width: 50px;
    }
    h3 {
      font-size: 24px;
      line-height: 50px;
    }
  }
}
