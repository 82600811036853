@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';

$mobile-width: 550px;

.alert-trigger {
  background-image: url('../../../../images/v2icons/bell@3x.png');
  height: 23px;
  width: 35px;
  display: inline-block;
  cursor: pointer;
  @include transition(0.2s);
  position: relative;
}

.alert-trigger.scrolling {
  height: 24px;
  width: 24px;
  background-image: url('../../../../images/icons/bell.svg');
}

.alerts-container {
  position: relative;
  display: inline-block;
  margin-left: 20px;
  vertical-align: middle;
  &.has-alerts {
    .alert-trigger:after {
      content: '';
      height: 7px;
      width: 7px;
      background-color: $error;
      border-radius: 50%;
      position: absolute;
      display: block;
      right: 0px;
      bottom: 0px;
    }
    .message {
      background-color: $warning;
    }
    .carat {
      @include tiny-carat($warning, $dark-neutral);
    }
  }
  .message {
    position: absolute;
    background-color: white;
    text-align: center;
    width: 200px;
    border: 1px solid $border-color;
    padding: 5px;
    top: 40px;
    right: 0;
    p {
      font-size: 14px;
    }
    span > a {
      @include emph;
      @include link($dark-neutral);
      margin: 4px auto 0;
      width: auto;
      height: auto;
      font-size: 12px !important;
    }
  }
  .carat {
    @include tiny-carat(white, $border-color);
    right: 10px;
    top: 0;
  }
}

@media screen and (max-width: 550px) {
  .alerts-container {
    display: none;
  }
}
