@import '~/src/styles/_variables.scss';
@import '../styles.module.scss';

$mobile-width: 550px;

.right-menu-wrap {
  @include right-menu;
}

.mobile-trigger {
  display: none;
}

.header-links {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  a {
    color: $text-color;
    font-size: 14px;
    display: block;
    margin-left: 25px;
    font-weight: 400;
    margin-top: 4px;

    &:not(.is-icon) {
      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background-color: transparent;
        border-radius: 30px;
        margin-top: 4px;
        transition: 0.3s background-color;
      }
      &:hover {
        &:after {
          background-color: $grey-300;
        }
      }

      &.active {
        &:after {
          background-color: $primary;
        }
      }
    }
  }
}

a.searchUser {
  img {
    width: 34px;
  }
}

// .header-links a {
//   // @include link($dark-neutral);

//   margin-left: 20px;
//   font-size: 14px;
//   // vertical-align: middle;
//   // for smooth transition
//   @include transition(0.4s);
//   overflow: hidden;
//   white-space: nowrap;
//   background-image: none;
//   img {
//     height: 22px;
//     margin-bottom: -1px;
//   }
//   &.is-icon {
//     font-size: 0;
//     line-height: 1;
//     border-color: transparent;
//   }
// }

$mobileMenuWidth: 220px;

@include media-breakpoint-down(xs) {
  .header-links-container {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 14px;
    line-height: 1;
    pointer-events: none;
    bottom: 0;
    @include ev-2;
  }
  .header-links {
    display: block;
    a {
      &:after {
        display: none !important;
      }
    }
  }
  .mobile-trigger {
    display: block;
    cursor: pointer;
    height: $header-height;
    width: 30px;
    pointer-events: auto;
    background-image: url('../../../images/icons/menu.svg');
    position: absolute;
    right: 20px;
    background-position: center;
    @include transition(0.2s);
    z-index: 11;
  }
  .header-links-container .header-links {
    @include transition(0.3s);
    z-index: 9;
    position: relative;
    right: -$mobileMenuWidth - 2;
    pointer-events: none;
    background-color: white;
    width: $mobileMenuWidth;
    min-height: 100vh;
    background-color: white;
    border-left: 1px solid $border-color;
    top: $header-height;
    > a {
      display: block;
      color: $text-color;
      font-size: 16px;
      width: 100%;
      margin: 0;
      text-align: right;
      padding: 15px 25px;
      border-bottom: 1px solid $border-color;
      &:last-child {
        border: none;
      }
    }
  }
  .scrolling {
    background-color: transparent;
    border: none;
    .mobile-trigger {
      background-image: url('../../../images/icons/menu.svg');
    }
    .header-links {
      display: block;
    }
  }
  .show-menu {
    pointer-events: auto;
    .mobile-trigger {
      background-image: url('../../../images/icons/main/menu.svg');
    }
    .header-links {
      right: 0px;
      pointer-events: auto;
    }
  }
}

// .header-links {
//   display: block;
// }
