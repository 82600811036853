@import '~/src/styles/volo/abstracts/_variables';
@import '~/src/styles/volo/abstracts/_registerVariables';

$banner-hide-amt: 45%; // How far the banner slides left when hidden. 100% is fully hidden
$banner-animation-timing: 350ms;
$banner-animation-easing: ease-in-out;

@keyframes slide-left-on-mount {
  from {
    transform: translateX($banner-hide-amt);
  }
  to {
    transform: translateX(0);
  }
}

// Is applied using an event-listener
.hidden {
  transform: translateX($banner-hide-amt);
}

.vp-ad-banner {
  // Some other things on the page have z-index
  z-index: 1000;
  position: fixed;
  right: -5px;
  bottom: 6em; // Has to be positioned high to avoid blocking the re-captcha banner.

  min-width: 18rem;
  border-radius: 0.25rem;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: $volo-medium-blue;
  outline: 3px solid $volo-primary;
  outline-offset: -3px;

  // Plays on mount
  animation: slide-left-on-mount $banner-animation-timing $banner-animation-easing;
  animation-fill-mode: backwards;
  animation-delay: 1s;

  // Plays on mouse hover
  transition:
    transform $banner-animation-timing $banner-animation-easing,
    outline-offset 100ms ease-in;

  &:hover {
    outline-offset: -1px;
  }

  a {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1.5em;
    padding: 1em;

    color: white;

    p,
    .vp-logo {
      flex: 1; // They should take equal amounts of space
    }

    .vp-logo {
      object-fit: contain;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.4));
    }

    .divider {
      background-color: currentColor; // whatever color the text above is
      width: 2px;
      height: 25px;
    }

    p {
      font-size: 1rem;
      font-weight: bold;
      text-align: center;
      line-height: 1.15;
    }
  }
}

@media (max-width: $breakpoint-md) {
  // Hide at smaller screens as to not overlap with the mobile app overlay
  .vp-ad-banner {
    display: none;
  }
}
