@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';

.edit-score-trigger {
  height: 18px;
  vertical-align: middle;
  margin-left: 5px;
  cursor: pointer;
}

.tournament-view {
  border-top: 1px dashed $lighter-neutral;
  margin-top: 20px;

  h2 {
    font-weight: 400;
    margin: 10px 0 20px;
  }

  .remove-tournament-btn {
    @include button($dark-neutral);
    margin: 0 0 0 10px;
    border-radius: 20px;
    padding: 3px 10px;
  }

  .tournament-round {
    border-top: 1px dashed $lighter-neutral;
    padding-top: 10px;
    margin-bottom: 20px;

    &.editable {
      > div {
        cursor: pointer;
      }

      img {
        position: absolute;
        width: 17px;
        margin-left: 5px;
      }
    }

    h2 {
      font-size: 16px;
      font-weight: 300;
      margin-bottom: 4px;
    }

    &:first-child {
      border: none;
    }

    h3 {
      font-weight: 600;
      font-style: italic;
      font-size: 12px;
      margin-bottom: 4px;
    }

    p {
      font-size: 12px;
      display: inline-block;
      vertical-align: middle;
      width: 20%;

      &.start-time {
        margin-right: 5px;
        width: 100px;
      }
    }

    .tournament-day {
      margin-top: 15px;
      &:first-child {
        margin-top: 0;
      }

      .tournament-item {
        margin-bottom: 4px;
        position: relative;
        border-bottom: 1px solid $lighter-neutral;
        padding-bottom: 2px;

        p {
          font-size: 12px;
          display: inline-block;
          position: relative;
          vertical-align: middle;
          width: 20%;

          &.start-time {
            margin-right: 5px;
            width: 100px;
          }
          .team-color {
            color: $lightish-neutral;
            margin-right: 4px;

            font-size: 10px;
          }

          &.team-placeholder {
            &:last-child {
              padding: 0 0 0 8px;
              &:before {
                content: 'vs. ';
                position: absolute;
                color: $lightish-neutral;
                font-size: 10px;
                left: -8px;
                top: 2px;
              }
            }

            &.forfeit {
              .team-name-score {
                color: #f00;
              }
            }

            &.unscored {
              background-color: #f00;
              color: #fff;
              padding: 5px 0 5px 15px;
              margin: 0 15px 0 -15px;
            }
          }
        }
      }
    }
  }

  &.editing {
    .section-header {
      text-align: center;
      width: 100%;
    }

    .tournament-round {
      display: inline-block;
      vertical-align: top;
      text-align: left;
      padding: 8px 0;
      width: 100%;
      border: 1px solid $light-neutral;
      border-radius: 2px;
      margin: 10px;
      position: relative;

      h2 {
        font-size: 18px;
        padding: 0 10px 4px;
        font-weight: 400;
      }

      h3 {
        width: 100%;
        font-size: 14px;
        font-weight: 300;
        background-color: #f3f6f6;
        padding: 7px 10px;
        margin: 3px 0;
        font-style: normal;
      }

      p {
        padding: 2px 10px;
        width: 20%;

        &.start-time {
          font-size: 12px;
          font-weight: 600;
          width: 60px;
          display: inline-block;
        }
      }

      > div {
        cursor: pointer;
      }

      img {
        position: absolute;
        width: 17px;
        right: 10px;
      }
    }
  }
}
