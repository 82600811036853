@use 'sass:math';

@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

$background: #ffffff;
$slider-handle-size: 5px;
$slider-size: 2px;
$slider-fix: 10px;

// Input slider
.u-slider {
  position: relative;
  display: inline-block;
  background-color: $background;
  border-radius: 3px;

  &:focus,
  &:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }

  .value {
    position: absolute;
    background-color: $dark-neutral; //hi
    border-radius: 3px;
  }

  .handle {
    position: absolute;
    width: $slider-size;
    height: $slider-size;
    cursor: grab;

    &:focus,
    &:active {
      cursor: grabbing;
      cursor: -moz-grabbing;
      cursor: -webkit-grabbing;
    }

    &:after {
      position: relative;
      display: block;
      content: '';
    }
  }
}

.u-slider-x,
.u-slider-y {
  .handle:after {
    width: $slider-size + $slider-fix;
    height: $slider-size + $slider-fix;
    background-color: #fff;
    border: 2px solid $dark-neutral; //hi
    border-radius: 50%;
  }
}

.u-slider-x {
  height: $slider-size;

  .handle {
    height: 100%;
    &:after {
      top: math.div(-$slider-fix, 2) - math.div($slider-size, 2);
      left: math.div(-($slider-size + $slider-fix), 2);
    }
  }

  .value {
    top: 0;
    height: 100%;
  }
}

.u-slider-y {
  width: $slider-size;

  .handle {
    width: 100%;
    &:after {
      top: math.div(-($slider-size + $slider-fix), 2);
      left: math.div(-$slider-fix, 2);
    }
  }

  .value {
    left: 0;
    width: 100%;
  }
}

.u-slider-xy {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: $dark-neutral; //hi
  border-radius: 0;

  .handle {
    position: absolute;

    &:after {
      position: relative;
      display: block;
      top: math.div(-$slider-handle-size, 2);
      left: math.div(-$slider-handle-size, 2);
      width: $slider-handle-size;
      height: $slider-handle-size;
      background-color: rgba(0, 0, 0, 0);
      border: 2px solid $dark-neutral;
      border-radius: 50%;
      content: '';
    }
  }
}

// input-moment
.im-btn {
  display: inline-block;
  background-color: #ffffff;
  border: 0;
  outline: 0;
  cursor: pointer;
  line-height: 1;

  &:before {
    margin-right: 6px;
  }
}

.m-input-moment {
  display: block;
  width: 330px;
  padding: 12px 15px;
  border-radius: 3px;
  border: 1px solid $lighter-neutral;

  .options {
    width: 100%;
    display: inline-block;
    margin-bottom: 4px;

    button {
      float: left;
      width: 50%;
      color: $dark-neutral; //hi
      text-align: center;
      font-size: 16px;
      padding: 7px;
      border: 1px solid $dark-neutral; //hi
      border-radius: 3px;

      &:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &.is-active {
        color: #ffffff;
        background-color: $dark-neutral; //hi
      }
    }
  }

  .tab {
    display: none;
    height: 300px;

    &.is-active {
      display: block;
    }
  }

  .tabs {
    margin-bottom: 11px;
  }

  .btn-save {
    @include caps;
    display: block;
    margin: 30px auto 0;
    &:hover {
      background: transparent;
      color: $dark-neutral;
    }
  }
}

// ===========
// TIME SLIDER
// ===========
.u-slider-time {
  background-color: $lighter-neutral;
  width: 100%;
}

// TIME TEXT
.m-time {
  color: $background;
  padding-top: 50px;

  .showtime {
    text-align: center;
  }

  .separater {
    display: inline-block;
    font-size: 32px;
    font-weight: bold;
    color: $dark-neutral; //hi
    width: 32px;
    height: 65px;
    line-height: 65px;
    text-align: center;
  }

  .time-text {
    position: relative;
    font-size: 15px;
    color: $dark-neutral; //hi
    margin-top: 7px;
    margin-bottom: 10px;
  }

  .sliders {
    padding: 0 10px;
  }

  .time {
    width: 65px;
    height: 65px;
    display: inline-block;
    font-size: 38px;
    line-height: 65px;
    background-color: $dark-neutral; //hi
    border-radius: 3px;
    text-align: center;
  }
}

.ampm {
  display: inline-block;
  font-size: 14px;
  color: $dark-neutral; //hi
  vertical-align: bottom;
  padding-left: 5px;
  font-weight: 600;
}

// INTERVAL TIME
.m-time.time-interval {
  padding-top: 10px;

  .separater,
  .time,
  .dash {
    height: 30px;
    line-height: 30px;
  }

  .separater {
    font-size: 14px;
    width: 10px;
  }

  .time-text {
    font-size: 14px;
    color: $med-neutral;
    margin: 20px auto 0;
  }

  .dash {
    font-size: 30px;
    color: $dark-neutral; //hi
    padding: 0 10px;
  }

  .time {
    width: 32px;
    height: 30px;
    font-size: 20px;
  }

  .showtime {
    display: inline-block;

    &.start {
      .time {
        background-color: $med-neutral;
      }
      .ampm {
        color: $med-neutral;
      }
    }
  }

  .sliders {
    display: inline-block;
    width: 50%;
  }
}
// Change start time color
.time-interval .start {
  .value {
    background-color: $med-neutral;
  }

  .u-slider-x,
  .u-slider-y {
    .handle:after {
      border-color: $med-neutral;
    }
  }
  .u-slider-xy {
    background-color: $med-neutral;
  }
}

// ========
// CALENDAR
// ========

// calendar
.m-calendar {
  display: inline-block;

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;
  }

  td {
    padding: 8px 0;
    text-align: center;
    cursor: pointer;
    color: $lighter-neutral;
    border: 2px solid $lighter-neutral;
  }

  thead {
    td {
      color: $dark-neutral; //hi
      font-size: 11px;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 12px;
    }
  }

  tbody td {
    color: $dark-neutral;

    &:hover {
      background: $primary;
      border-color: $primary;
      color: white;
      // color: $background;
    }
  }

  .current-day {
    color: white;
    background-color: $dark-neutral;
    font-weight: bold;
    cursor: default;
    pointer-events: none;
  }

  .gray-day {
    background-color: #c5c5c5;
    cursor: not-allowed;
    color: white;
  }

  .other-month {
    background-color: #f7f7f7;
    color: #f7f7f7;
    cursor: default;
    &:hover {
      background-color: #f7f7f7;
      color: #f7f7f7;
      border: 2px solid #ebeeee;
    }
  }

  .toolbar {
    line-height: 30px;
    color: $dark-neutral; //hi
    text-align: center;
    margin-bottom: 13px;

    button {
      position: relative;
      width: 30px;
      height: 30px;
      line-height: 30px;
      color: #ffffff;
      border: 1px solid $dark-neutral; //hi
      border-radius: 50%;
      background: $dark-neutral; //hi
      font-size: 20px;
      padding: 0;
      text-align: center;
      outline: 0;
      z-index: 5;
      cursor: pointer;
    }

    .prev-month {
      float: left;
    }

    .next-month {
      float: right;
    }

    .prev-month,
    .next-month {
      cursor: pointer;
    }

    .current-date {
      color: $dark-neutral; //hi
    }
  }
}

.cal-wrap .alert {
  margin: 15px auto 0;
  color: red;
  font-size: 12px;
}

// customizations
.moment-picker.picker-popup {
  z-index: 1500 !important;
  position: fixed !important;
  background-color: $background;
  @include popup(330px);
  padding: 20px;
}

.cancel-button {
  @include caps;
  font-size: 12px;
  color: $dark-neutral;
  text-align: left;
  cursor: pointer;
  display: inline-block;
}

$simple-slider-size: 1px;
$simple-slider-fix: 8px;
$simple-slider-handle-size: 8px;

.simple.m-input-moment {
  width: 300px;
  padding: 11px 0px;
  border: none;
  height: auto;
  .tab {
    height: auto;
  }

  .time-display-wrap {
    width: 100%;
    text-align: center;
  }

  .time-interval {
    height: auto;

    .u-slider .handle {
      width: $simple-slider-size;
      height: $simple-slider-size;
    }

    .u-slider-x,
    .u-slider-y {
      .handle:after {
        width: $simple-slider-size + $simple-slider-fix;
        height: $simple-slider-size + $simple-slider-fix;
        border: 2px solid $light-neutral;
      }
    }

    .u-slider-x {
      height: $simple-slider-size;

      .handle:after {
        top: math.div(-$simple-slider-fix, 2);
        left: math.div(-($simple-slider-size + $simple-slider-fix), 2);
      }
    }

    .u-slider-y {
      width: $simple-slider-size;

      .handle:after {
        top: math.div(-($simple-slider-size + $simple-slider-fix), 2);
        left: math.div(-$simple-slider-fix, 2);
      }
    }

    .u-slider-xy {
      height: $simple-slider-size;
      .handle:after {
        top: math.div(-$simple-slider-handle-size, 2);
        left: math.div(-$simple-slider-handle-size, 2);
        width: $simple-slider-handle-size;
        height: $simple-slider-handle-size;
      }
    }

    .value {
      background-color: $dark-neutral;
    }
  }

  .m-time {
    color: $dark-neutral;
    padding-top: 0px;
    width: 100%;
    .separater,
    .time,
    .dash {
      height: 15px;
      line-height: 15px;
    }
    .dash {
      font-size: 12px;
      color: $light-neutral;
    }

    .separater {
      font-size: 10px;
      font-weight: 600;
      color: $light-neutral;
      width: 10px;
      height: 15px;
      line-height: 15px;
    }

    .time {
      font-weight: 600;
      width: 15px;
      height: 15px;
      font-size: 15px;
      line-height: 15px;
      background-color: transparent;
    }
    .showtime.start {
      .time {
        background-color: transparent;
        color: $dark-neutral;
      }
      .ampm {
        color: $dark-neutral;
      }
    }

    .ampm {
      font-size: 12px;
      font-weight: 300;
      color: $dark-neutral;
    }

    .sliders {
      padding: 0;
    }

    .time-text {
      font-size: 12px;
      left: 0;
      color: $light-neutral;
      margin-top: 5px;
      margin-bottom: 0;
    }
  }
}

/* DayPicker styles */

.DayPicker {
  display: inline-block;
  font-size: 1rem;
}

.DayPicker-wrapper {
  position: relative;

  flex-direction: row;
  padding-bottom: 1em;

  -webkit-user-select: none;

  -moz-user-select: none;

  -ms-user-select: none;

  user-select: none;
}

.DayPicker-Months {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.DayPicker-Month {
  display: table;
  margin: 0 1em;
  margin-top: 1em;
  border-spacing: 0;
  border-collapse: collapse;

  -webkit-user-select: none;

  -moz-user-select: none;

  -ms-user-select: none;

  user-select: none;
}

.DayPicker-NavBar {
}

.DayPicker-NavButton {
  position: absolute;
  top: 1em;
  right: 1.5em;
  left: auto;

  display: inline-block;
  margin-top: 2px;
  width: 1.25em;
  height: 1.25em;
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
  color: #8b9898;
  cursor: pointer;
}

.DayPicker-NavButton:hover {
  opacity: 0.8;
}

.DayPicker-NavButton--prev {
  margin-right: 1.5em;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC');
}

.DayPicker-NavButton--next {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==');
}

.DayPicker-NavButton--interactionDisabled {
  display: none;
}

.DayPicker-Caption {
  display: table-caption;
  margin-bottom: 0.5em;
  padding: 0 0.5em;
  text-align: left;
}

.DayPicker-Caption > div {
  font-weight: 500;
  font-size: 1.15em;
}

.DayPicker-Weekdays {
  display: table-header-group;
  margin-top: 1em;
}

.DayPicker-WeekdaysRow {
  display: table-row;
}

.DayPicker-Weekday {
  display: table-cell;
  padding: 0.5em;
  color: #8b9898;
  text-align: center;
  font-size: 0.875em;
}

.DayPicker-Weekday abbr[title] {
  border-bottom: none;
  text-decoration: none;
}

.DayPicker-Body {
  display: table-row-group;
}

.DayPicker-Week {
  display: table-row;
}

.DayPicker-Day {
  display: table-cell;
  padding: 0.5em;
  border-radius: 50%;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
}

.DayPicker-WeekNumber {
  display: table-cell;
  padding: 0.5em;
  min-width: 1em;
  border-right: 1px solid #eaecec;
  color: #8b9898;
  vertical-align: middle;
  text-align: right;
  font-size: 0.75em;
  cursor: pointer;
}

.DayPicker--interactionDisabled .DayPicker-Day {
  cursor: default;
}

.DayPicker-Footer {
  padding-top: 0.5em;
}

.DayPicker-TodayButton {
  border: none;
  background-color: transparent;
  background-image: none;
  box-shadow: none;
  color: #4a90e2;
  font-size: 0.875em;
  cursor: pointer;
}

/* Default modifiers */

.DayPicker-Day--today {
  color: #d0021b;
  font-weight: 700;
}

.DayPicker-Day--outside {
  color: #8b9898;
  cursor: default;
}

.DayPicker-Day--disabled {
  color: #dce0e0;
  cursor: default;
  /* background-color: #eff1f1; */
}

/* Example modifiers */

.DayPicker-Day--sunday {
  background-color: #f7f8f8;
}

.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
  color: #dce0e0;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  position: relative;

  background-color: #4a90e2;
  color: #f0f8ff;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: #51a0fa;
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(
    .DayPicker-Day--outside
  ):hover {
  background-color: #f0f8ff;
}

/* DayPickerInput */

.DayPickerInput {
  display: inline-block;
}

.DayPickerInput-OverlayWrapper {
  position: relative;
}

.DayPickerInput-Overlay {
  position: absolute;
  left: 0;
  z-index: 1;

  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.editable-date-placeholder {
  background-color: white;
  height: 45px;
  /*width: 1000px;*/
  /*color: green;*/
  /*margin: 100px;*/
  padding: 7px;
  width: 100%;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .editable-clear-icon {
    align-self: flex-end;
    /*margin-top: 40px;*/
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    cursor: pointer;
  }

  .editable-date-text {
    font-weight: 500;
    padding: 7px;
    padding-left: 0.7em;
  }
}
