@import '~/src/styles/_variables.scss';
@import '~/src/styles/_mixins.scss';

%box-defaults {
  background-color: $grey-100;
  border-radius: 12px;
  padding: 8px;
  box-shadow: 1px 1px 3px rgba(38, 50, 56, 0.1);
  place-self: stretch;

  &:hover {
    background-color: lighten($grey-100, 1%);
    box-shadow: 3px 2px 3px rgba(38, 50, 56, 0.1);
  }
}

.notebook {
  label {
    align-self: flex-start;
    font-size: 12px;
    font-weight: 800;
    margin-bottom: 12px;
    color: $rivall-blue;
  }
  .calendar {
    grid-area: calendar;
    @extend %box-defaults;
    :global(.DayPicker-wrapper) {
      padding-bottom: 0;
    }
    :global(.DayPicker-Day) {
      &:hover {
        cursor: pointer;
      }
    }
  }
  .cost {
    grid-area: cost;
    @extend %box-defaults;
  }
  .extra {
    grid-area: extra;
    @extend %box-defaults;
  }
  .plus {
    display: flex;
    font-size: 5em;
    justify-content: center;
    align-items: center;
    grid-area: plus;
    cursor: pointer;
    border-radius: 16px;
    box-shadow: 1px 1px 3px rgba(38, 50, 56, 0.5);
    background-color: $rivall-blue;
    color: $grey-200;

    &:hover {
      color: $grey-600;
      background-color: $grey-100;
    }
  }
  .preview {
    grid-area: preview;
    @extend %box-defaults;
    .finalList {
      height: 30em;
      overflow: scroll;
      padding: 16px;
      display: flex;
      flex-direction: column;
    }
    .total {
      margin-top: 24px;
      padding: 10px;
      text-align: center;
      background-color: $rivall-blue;
      border-radius: 12px;
      color: $grey-200;
      &:hover {
        background-color: lighten($rivall-blue, 10%);
      }
    }
  }
  .reference {
    grid-area: reference;
    @extend %box-defaults;
  }
  .sublocation {
    grid-area: sublocation;
    @extend %box-defaults;
  }
  .time {
    grid-area: time;
    @extend %box-defaults;
    small {
      color: black;
      font-weight: normal;
      text-transform: none;
    }
  }

  display: grid;
  grid-template-columns: 1.1fr 0.8fr 1.1fr;
  grid-template-rows: 1fr auto auto auto;
  column-gap: 16px;
  row-gap: 16px;
  grid-template-areas:
    'calendar time preview'
    'calendar cost preview'
    'sublocation reference preview'
    'extra plus preview';
}

.item {
  background-color: white;
  box-shadow:
    0px 4px 8px rgba(38, 50, 56, 0.08),
    0px 2px 4px rgba(38, 50, 56, 0.16),
    0px -4px 4px rgba(0, 0, 0, 0.03);
  padding: 4px 8px;
  margin: 6px 0;
  border-radius: 8px;
  transition: transform 0.3s ease-out;
  label {
    color: $rivall-blue;
    align-self: flex-start;
    font-size: 12px;
    font-weight: 800;
    margin: 0 0 2px 0;
    padding: 0 !important;
  }

  .hiddenSection {
    padding: 0 0 8px 0;

    .expanded {
      height: 3rem;
      transform: scaleY(0);
      transform-origin: top;
    }
    .collapsed {
      transform: scaleY(1);
    }

    .hiddenRow {
      display: flex;
      justify-content: space-between;
      margin: 4px 8px;
      div {
        text-align: left;
      }
    }

    label {
      margin-top: 6px;
    }
  }

  .visibleSection {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .itemDate {
      border-radius: 6px;
      color: $rivall-blue;
      font-size: 16px;
      font-weight: 800;
      padding: 5px;
      cursor: pointer;
      left: 1.5rem;
      position: relative;

      &::before {
        content: '\A';
        position: absolute;
        border: 0.5em solid transparent;
        border-left-color: $rivall-blue;
        transform-origin: 25% 60%;
        transition: transform 0.25s;
        left: -1rem;
      }

      &.down::before {
        transform: rotate(90deg);
        transition: transform 0.25s;
      }
    }
    .options {
      button {
        padding: 0 4px;
        margin: 0;
      }

      img {
        height: 18px;
        width: auto;
      }
      // delete and edit svg are centered different
      .editButton {
        img {
          padding: 1px 0;
        }
      }
    }
  }
}

.separator {
  border-bottom: 1px solid $grey-300;
  width: 75%;
}

.currencyInput {
  border-radius: 1px;
  padding: 0.75vw 1vw;
  margin: 0;
  width: 100%;
  border: 1px solid $grey-200;
}

.editing {
  background-color: $grey-300;
}
