@use '../abstracts/' as *;

.homepage-v2 {
  width: 100vw;
  box-sizing: border-box;
  margin: 0;

  h1 {
    color: $rivall-navy;
    font-size: 2.5rem;
    letter-spacing: -1px;
    margin-bottom: 1rem;
  }

  p {
    color: $rivall-navy;
    font-size: 1rem;
    margin-top: 1rem;
  }

  .contact-form {
    @include media-breakpoint-up(lg) {
      padding-right: 10rem;
      padding-left: 10rem;
    }

    .label {
      color: $rivall-navy;
      margin-top: 8px;
    }

    button {
      background-color: $neon-teal;
      border-color: $neon-teal;

      &:hover {
        border-color: $neon-teal;
        box-shadow: 0 0 0 2px $neon-teal;
      }
    }
  }

  .fancyText {
    color: $rivall-navy;
    text-shadow: 0px 2px 0px #ffffff;
    margin-bottom: 10px;
    background: linear-gradient(transparent 60%, #e9ff70 20%) no-repeat;
    border-radius: 6px;
    width: fit-content;
    width: -moz-fit-content; /* Firefox */
    width: -webkit-fit-content; /* Chrome */
  }

  .primary-text {
    font-weight: 800;
    color: $volo-primary;
  }

  .secondary-text {
    color: $volo-primary;
  }

  .section-wrapper {
    &:nth-child(2n + 1) {
      background-color: $volo-secondary-light;
    }
  }

  .section {
    padding-top: 60px;
    padding-bottom: 60px;

    .img-fit-cover {
      border-radius: 4px;
      object-fit: cover;
      width: 100%;
    }

    p,
    p.small {
      text-align: left;
      font-size: 14px;
      line-height: 22px;
    }

    .sectionHeading {
      margin: 0.75rem auto;
      @include media-breakpoint-up(sm) {
        &.center {
          h2 {
            width: fit-content;
            width: -moz-fit-content; /* Firefox */
            width: -webkit-fit-content; /* Chrome */
            margin: 0 auto;
          }

          p {
            width: 480px;
            margin-left: auto;
            margin-right: auto;
            font-size: 16px;
            text-align: center;
          }
        }
      }
    }

    @include media-breakpoint-up(lg) {
      width: 100%;
      padding-right: 9rem;
      padding-left: 9rem;
    }
  }

  .sectionCard {
    padding: 40px 20px;
    height: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    text-align: left;

    &.thinCard {
      @include media-breakpoint-up(sm) {
        min-height: 303px;
      }
    }

    img {
      height: 100%;
      object-fit: cover;
    }
  }

  .ctaButton {
    margin: 30px 0;
    background: $rivall-navy;
    border: 2.6px solid $rivall-navy;
    text-transform: uppercase;
    font-weight: 700;
    border-radius: 4px;
    a {
      color: $rivall-navy;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  .divider {
    margin: 20px 0;
    height: 5px;
    width: 35px;
    background-color: #4ce0d2;
  }

  .headerCta {
    margin: 30px 0;
    background: #4ce0d2;
    border: 2.6px solid #4ce0d2;
    text-transform: uppercase;
    font-weight: 700;
    border-radius: 4px;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
    a {
      color: #4ce0d2;
    }

    &:hover {
      box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.5);
    }
  }

  .reduceMargin {
    @include media-breakpoint-up(lg) {
      margin-right: -125px;
      margin-left: -125px;
    }
  }
}
