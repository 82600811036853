@use '../abstracts/' as *;

.corporate-sticky-hero {
  .corporate-sticky-bar {
    display: none;

    @include media-breakpoint-up(sm) {
      border-top: 1px solid $grey-300;
      border-bottom: 1px solid $grey-300;
      top: 54px;
      width: 100%;
      display: none;
      justify-content: center;
      align-items: center;
      height: 66px;
      z-index: 999;
      text-align: center;
      position: fixed;
      background: white;
      transition: display 0.3s ease-out-in;
    }
  }
}
